.full-layout {
  width: 100%;
  min-height: 100vh !important;
  height: 100%;
  background: #ffffff !important;
  overflow: hidden;

  .content-layout {
    background: #ffffff;

    .main-layout {
      padding-top: 24px;
      width: 85%;
      margin: auto;
      background: #ffffff;

      .eun-content {
        background: #ffffff;
        padding: 24px;
        margin: auto;
        width: 100%;
        min-height: 280px;
        max-width: 1300px;
      }
    }
  }
}

.footer {
  padding: 24px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1300px;

  .line {
    width: 1.5px;
    height: 10px;
    background-color: rgb(204, 204, 204);
    margin: 0 5px;
  }
}

@media screen and (max-width: 700px) {
  .footer {
    flex-direction: column;

    .line {
      display: none;
    }
  }

  .full-layout {
    .content-layout {
      .main-layout {
        width: 95%;

        .eun-content {
          padding: 10px;
        }
      }
    }
  }
}
