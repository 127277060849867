@import "styles/common.scss";

.convert-wrapper {
  .header {
    margin-bottom: 20px;

    .title {
      font-size: $fontSize;
      font-weight: $fontWeight;
      // margin-bottom: $marginBottom;
      color: $fontColor;
    }

    .desc {
      color: $descColor;
    }
  }

  .button-box {
    margin-top: 60px;
    text-align: center;
  }
}

.select-wrapper {
  margin: 10px 0;
  .select-title {
    padding: 10px 0;
    font-size: 1.2em;
    font-weight: 700;
  }
  .select-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .convert-box {
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 10px;

      .icon-box {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #e7f3fc;
        border: 2px solid #4b9af4;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 5px;
        transition: all 0.2s;
        &:hover {
          background-color: #4b9af4;
        }

        .img {
          width: 60%;
          img {
            width: 100%;
          }
        }

        &.select-convert {
          background-color: #4b9af4;
        }
      }

      .title-box {
        font-size: 0.8em;
        opacity: 0.8;
      }
    }
  }
}
