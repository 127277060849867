@import "styles/common.scss";

.analysis-wrapper {
  &.analysis-fix-wrapper {
    // padding-top: 165px;
  }
}

.summary {
  .title-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .title {
      font-size: $fontSize;
      font-weight: $fontWeight;
      margin-bottom: $marginBottom;
      color: $fontColor;
    }

    .download-box {
      display: flex;
      align-items: center;

      .btn-guide {
        opacity: 0.4;
        color: #929292;
        margin-left: 8px;
        font-weight: normal;
        transition: all 0.3s;
        font-size: 12px;
        &:hover {
          opacity: 1;
        }
      }

      .btn-box {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.3em;
        font-weight: bold;
        cursor: pointer;
        margin: 0 10px;

        &.excel-btn {
          color: #007337;
        }

        &.indel-btn {
          color: #ff4e5d;
        }

        &.report-btn {
          color: #ffd64e;
        }
      }
    }
  }
}

.analysis-container {
  margin-bottom: 50px;
}

.analysis-id {
  font-size: 1.4em;
  font-weight: bold;
  color: #ffffff;
  border-bottom: 1px solid #d9d9d9;
  padding: 10px;
  background-color: #545454;
}
.sum-title {
  padding-bottom: 5px;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  .sum-title-text {
    font-size: 1.1em;
    color: #424242;
  }
}

.color-wrapper {
  display: flex;

  .color-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    .bg {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }

    .text {
      font-size: 0.8em;
    }

    &.origin-val {
      .bg {
        background-color: $originVal;
      }
    }

    &.target-val {
      .bg {
        background-color: $targetVal;
      }
    }

    &.sub-val {
      .bg {
        background-color: $subVal;
      }
    }

    &.deletion-val {
      .bg {
        background-color: $deletionVal;
      }
    }

    &.insertion-val {
      .bg {
        background-color: $insertionVal;
      }
    }
  }
}
.summary-item {
  padding: 15px 0;
  transition: all 0.3s;
  margin-bottom: 30px;

  &.fix-sequence {
    .fix-wrapper {
      width: 85%;
      max-width: 1286px;
      margin: auto;
      padding: 10px;
      // box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
      // box-shadow: -1px 19px 24px -12px rgba(0, 0, 0, 0.39);
      box-shadow: -5px -11px 58px 6px rgba(0, 0, 0, 0.39);
      background-color: #514f4f;
      color: #ffffff;

      .sum-title-text {
        color: #ffffff;
      }
    }
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
    padding: 5px 14px;
    transition: all 0.5s;
    transition: opacity 0.4s, top 0.4s;

    &.close {
      opacity: 0;
      top: -200px;
    }
  }
}

.sum-info {
  display: flex;
  align-items: center;
  .info {
    display: flex;
    align-items: center;
    margin-right: 5px;
    .text {
      font-size: 0.9em;
    }
    .info-icon {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }

    .blue {
      background-color: #00a2c2;
    }

    .green {
      background-color: #ff9d1c;
    }

    .red {
      background-color: #c21700;
    }
  }
}

.sum-val {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 10px 0;

  &.wt-wrapper {
    display: block;
    word-break: break-all;
    text-align: center;
  }

  .sum-primerSeq {
    color: #00a2c2;
    font-weight: bold;
  }

  .sum-rgenSeq {
    color: #ff9d1c;
    font-weight: bold;
  }

  .sum-coreSeq {
    color: #c21700;
    font-weight: bold;
  }
}

.result-item {
  flex: 1;
  text-align: center;
  margin: 0 15px;
  .result-title {
    font-size: 1em;
    color: #9c9c9c;
    margin-bottom: 5px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .result-val {
    font-size: 1.4em;
    font-weight: bold;
    color: #5c5c5c;
  }
}

:global {
  .value-wrapper {
    display: flex;
    //flex-direction: column;
    flex-wrap: wrap;

    .graphic {
      letter-spacing: 5.15px;
    }

    .value-box {
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      .value {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8em;
      }

      .target-val {
        font-weight: bold;
        background-color: $targetVal;
        color: #ffffff;
      }

      .insertion-val {
        font-weight: bold;
        background-color: $insertionVal;
        color: #ffffff;
      }

      .deletion-val {
        font-weight: bold;
        background-color: $deletionVal;
        color: #ffffff;
      }

      .sub-val {
        font-weight: bold;
        background-color: $subVal;
        color: #ffffff;
      }
    }
  }
}

.char-box {
  flex: 1;

  &.origin-char {
    text-align: center;
    font-weight: bold;
  }

  .char {
    text-align: center;
    cursor: pointer;
    position: relative;
    padding: 3px 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    &.char-title {
      font-weight: bold;
    }

    .bg {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    span {
      z-index: 100;
    }

    &.target-val {
      .bg {
        background-color: $targetVal;
      }
    }

    &.origin-val {
      .bg {
        background-color: $originVal;
      }
    }

    &.changed {
      span {
        color: #ffffff;
      }
    }

    &.sub-val {
      .bg {
        background-color: $subVal;
      }
    }
  }
}

.change-seq {
  display: flex;
  align-items: center;

  .seq {
    font-size: 3em;
    margin: 0px 10px;
    font-weight: bold;
  }

  & svg {
    font-size: 2em;
    margin: 0 20px;
  }
}

.change-result {
  width: 100%;
  text-align: center;
  font-size: 2.5em;
  font-weight: bold;
  cursor: pointer;
}

.total {
  width: 100%;
  text-align: center;
  font-size: 2em;
}
.excel-dwn {
  cursor: pointer;
  font-size: 1.2em;
  & * {
    font-size: 1em;
  }
}

@media screen and (max-width: "1000px") {
  .sum-title {
    flex-direction: column;

    .sum-title-text {
      margin-bottom: 5px;
    }

    .sum-info {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .title-box {
    flex-direction: column;
    .download-box {
      flex-direction: column;

      .btn-box {
        width: 100%;
        justify-content: flex-start !important;
        margin-bottom: 10px !important;
      }
    }
  }

  .sum-val {
    flex-direction: column;
    & * {
      word-break: break-all;
    }
  }
}
