html,
body,
canvas {
  margin: 0px;
  height: 100vh;
  font-size: 0;
}

#punch {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  background-color: transparent;
  border: 0;
  outline: 0;
  font-size: 30px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
  border: 1px solid transparent;
  padding: 5px 20px;
  transition: all 350ms;
}

#punch:hover {
  border: 1px solid white;
}

$pulseLength: 2s;
$pulseSize: 50px;

.intro-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(135deg, #002722 0%, #0157ad 100%);

  &.stop {
    animation: close 1.5s;
  }

  &.hidden {
    display: none;
  }

  .img-box {
    width: 500px;
    height: 500px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .img {
      width: 80%;
      opacity: 0.5;
      animation: opAnime 41s ease infinite;
      img {
        width: 100%;
      }
    }
  }

  .logo-box {
    display: block;
    color: #ffffff;
    font-size: 1.5em;
    width: 400px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    div {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .logo {
    animation: showup 4s infinite;
    width: 45px;
  }

  .desc {
    width: 0px;
    animation: reveal 4s infinite;
    span {
      margin-left: -355px;
      animation: slidein 4s infinite;
    }
  }
}

.loading-logo {
  position: absolute;
  height: 500px;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.25),
    rgba(10, 3, 70, 0.25)
  );
  background-size: 400% 400%;
  border-radius: 360px;
  padding: 50px;

  animation: loadLogoBack 41s ease infinite;

  svg {
    height: 100%;
    width: 100%;
    fill: white;
    stroke: white;
    path {
      fill: white;
      stroke: white;
    }
    * {
      fill: white !important;
      stroke: white;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    // background-color: rgba(white, .1);
    border-radius: 360px;
    animation: pulse $pulseLength ease-out infinite;
    border: 0px solid transparent;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    // background-color: rgba(white, .1);
    border-radius: 360px;
    animation: pulseBig $pulseLength ease-out infinite;
    border: 0px solid transparent;
  }
}

@keyframes pulse {
  0% {
    border: 0px solid rgba(white, 0.5);
    top: 0px;
    left: 0px;
  }
  90% {
    border: $pulseSize/2 solid rgba(white, 0);
    // top: -$pulseSize/2;
    // left: -$pulseSize/2;
  }
}

@keyframes pulseBig {
  0% {
    border: 0px solid rgba(white, 0.5);
    top: 0px;
    left: 0px;
  }
  90% {
    border: $pulseSize solid rgba(white, 0);
    // top: -$pulseSize;
    // left: -$pulseSize;
  }
}
@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}

@keyframes opAnime {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showup {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slidein {
  0% {
    margin-left: -800px;
  }
  20% {
    margin-left: -800px;
  }
  35% {
    margin-left: 0px;
  }
  100% {
    margin-left: 0px;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: 220px;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 220px;
  }
}

@keyframes close {
  0% {
    opacity: 1;
    display: flex;
  }

  90% {
    opacity: 0;
  }

  100% {
    display: none;
    visibility: hidden;
  }
}
