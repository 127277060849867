.login-container {
  background: rgb(44, 172, 209);
  background: linear-gradient(
    138deg,
    rgba(44, 172, 209, 1) 0%,
    rgba(44, 101, 177, 1) 100%
  );
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title-box {
  text-align: center;
  margin: 15px 0;
  .title {
    color: #ffffff;
    font-size: 1.8em;
    font-weight: bold;
  }
}

.login-box {
  position: absolute;
  top: 20%;
  left: 200px;
  margin: 50px 0;
  .login-form {
    width: 300px;
  }
  .title {
    color: #ffffff;
    font-size: 6em;
    font-weight: 100;
  }
  .sub-desc {
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 30px;
  }

  .input-box {
    width: 100%;

    &.last-input {
      margin-bottom: 0 !important;
    }
    svg {
      color: rgba(255, 255, 255, 0.55);
    }
    input {
      background-color: transparent !important;
      border-radius: 0px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      -ms-border-radius: 0px;
      -o-border-radius: 0px;
      color: #ffffff;
      border: 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);

      &:focus {
        background-color: transparent;
      }
    }
  }
}

.button-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;

  cursor: pointer;

  svg {
    height: 35px;
    width: 90px;
    fill: none;
    stroke-width: 3;
    display: block;

    .o1 {
      stroke: rgba(#ffffff, 0.5);
      fill: transparent;
      transition: all 1s ease-in-out;
    }

    .o2 {
      stroke: white;
      stroke-dasharray: 20 420;
      stroke-dashoffset: 20;
      transition: all 1s ease-in-out;
    }
  }

  span {
    position: absolute;
    margin: auto 0;
    text-transform: uppercase;
    font-size: 0.9em;
    color: #ffffff;
  }

  &:hover {
    .o1 {
    }

    .o2 {
      stroke-dashoffset: -420;
    }
  }
}

.circle {
  width: 800px;
  height: 800px;
  position: absolute;
  top: 30px;
  right: 30px;

  & img {
    width: 100%;
    height: 100%;
  }
}

.project-info {
  position: absolute;
  top: 150px;
  right: 250px;
  color: #ffffff;
  padding: 2px 12px;
  border-left: 1.5px solid rgba(#ffffff, 0.8);
  opacity: 1;
  .desc {
    margin-bottom: 10px;
  }
  .small {
    font-size: 0.9em;
    opacity: 0.8;
  }

  .email {
    font-size: 0.9em;
    text-decoration: underline;
    opacity: 0.8;
  }

  .sns-box {
    margin-top: 10px;
    a {
      color: #ffffff;
    }
    & * {
      color: #ffffff;
      font-size: 1.2em;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: "1000px") {
  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-box {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .title {
      font-size: 4em;
    }
  }

  .project-info {
    display: none;
  }

  .circle {
    top: auto;
    bottom: -100px;
    width: 400px;
    height: 400px;
    right: -100px;
  }

  .sub-desc {
    text-align: center;
    padding: 0 20px;
  }
}

.for-daeun {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #ffffff;
  font-weight: 200;
  opacity: 0.3;
}

.without-login {
  font-size: 0.9em;
  margin-top: 5px;
  margin-bottom: 30px;
  color: #ffffff;
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.4s;

  &:hover {
    opacity: 1;
  }
}
