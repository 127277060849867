@import url(https://fonts.googleapis.com/css?family=Roboto:100,400,500&display=swap);
.PageTemplate_full-layout__2Hd4z {
  width: 100%;
  min-height: 100vh !important;
  height: 100%;
  background: #ffffff !important;
  overflow: hidden; }
  .PageTemplate_full-layout__2Hd4z .PageTemplate_content-layout__1Ic5m {
    background: #ffffff; }
    .PageTemplate_full-layout__2Hd4z .PageTemplate_content-layout__1Ic5m .PageTemplate_main-layout__2dczb {
      padding-top: 24px;
      width: 85%;
      margin: auto;
      background: #ffffff; }
      .PageTemplate_full-layout__2Hd4z .PageTemplate_content-layout__1Ic5m .PageTemplate_main-layout__2dczb .PageTemplate_eun-content__3wswl {
        background: #ffffff;
        padding: 24px;
        margin: auto;
        width: 100%;
        min-height: 280px;
        max-width: 1300px; }

.PageTemplate_footer__3YZKv {
  padding: 24px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1300px; }
  .PageTemplate_footer__3YZKv .PageTemplate_line__1BYnV {
    width: 1.5px;
    height: 10px;
    background-color: #cccccc;
    margin: 0 5px; }

@media screen and (max-width: 700px) {
  .PageTemplate_footer__3YZKv {
    flex-direction: column; }
    .PageTemplate_footer__3YZKv .PageTemplate_line__1BYnV {
      display: none; }
  .PageTemplate_full-layout__2Hd4z .PageTemplate_content-layout__1Ic5m .PageTemplate_main-layout__2dczb {
    width: 95%; }
    .PageTemplate_full-layout__2Hd4z .PageTemplate_content-layout__1Ic5m .PageTemplate_main-layout__2dczb .PageTemplate_eun-content__3wswl {
      padding: 10px; } }

.TopBar_logo__3TehO {
  display: flex;
  cursor: pointer;
  align-items: center; }
  .TopBar_logo__3TehO .TopBar_logo-text__1F95y {
    font-size: 1.4em;
    color: #ffffff;
    font-weight: bold;
    margin-right: 10px; }
  .TopBar_logo__3TehO .TopBar_img-box__2lq4O {
    width: 40px;
    margin-right: 5px; }
    .TopBar_logo__3TehO .TopBar_img-box__2lq4O img {
      width: 100%; }

.TopBar_header-box__syGJz {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  max-width: 1300px;
  margin: auto; }
  .TopBar_header-box__syGJz .TopBar_right-side__Equ66,
  .TopBar_header-box__syGJz .TopBar_left-side__2bxZN {
    display: flex;
    align-items: center;
    justify-content: center; }
  .TopBar_header-box__syGJz .TopBar_menu-box__2jGtC {
    margin: 0 10px;
    cursor: pointer;
    opacity: 0.8;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
    .TopBar_header-box__syGJz .TopBar_menu-box__2jGtC:hover {
      opacity: 1; }
    .TopBar_header-box__syGJz .TopBar_menu-box__2jGtC .TopBar_menu__DOLC_ {
      color: #ffffff;
      font-size: 0.9em; }
  .TopBar_header-box__syGJz .TopBar_run-sample__yketz {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
    position: relative; }
    .TopBar_header-box__syGJz .TopBar_run-sample__yketz svg {
      height: 35px;
      width: 110px;
      fill: none;
      stroke-width: 3;
      display: block; }
      .TopBar_header-box__syGJz .TopBar_run-sample__yketz svg .TopBar_o1__1krCO {
        stroke: rgba(255, 255, 255, 0.5);
        fill: transparent;
        -webkit-transition: all 1s ease-in-out;
        transition: all 1s ease-in-out; }
      .TopBar_header-box__syGJz .TopBar_run-sample__yketz svg .TopBar_o2__2d6mh {
        stroke: white;
        stroke-dasharray: 20 420;
        stroke-dashoffset: 20;
        -webkit-transition: all 1s ease-in-out;
        transition: all 1s ease-in-out; }
    .TopBar_header-box__syGJz .TopBar_run-sample__yketz span {
      position: absolute;
      margin: auto 0;
      text-transform: uppercase;
      font-size: 0.9em;
      color: #ffffff; }
    .TopBar_header-box__syGJz .TopBar_run-sample__yketz:hover .TopBar_o2__2d6mh {
      stroke-dashoffset: -420; }
  .TopBar_header-box__syGJz .TopBar_logout__inbYx {
    color: #ffffff;
    cursor: pointer;
    font-size: 1.1em;
    font-weight: bold; }

@media screen and (max-width: 700px) {
  .TopBar_header-box__syGJz .TopBar_menu-box__2jGtC,
  .TopBar_header-box__syGJz .TopBar_right-side__Equ66 {
    display: none; } }

.Question_question-icon__2Us-c {
  position: fixed;
  z-index: 20;
  right: 24px;
  bottom: 24px;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #4b9af4;
  background-color: #e7f3fc;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 6px 0px !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .Question_question-icon__2Us-c:hover {
    -webkit-transform: translate(0, -5px);
            transform: translate(0, -5px); }
  .Question_question-icon__2Us-c img {
    width: 50px; }

.Question_speech-bubble__unwa5 {
  top: -60px;
  position: absolute;
  background: #5f5f5f;
  border-radius: 0.4em;
  color: #ffffff;
  width: 117px;
  padding: 10px;
  right: 0px;
  opacity: 0; }
  .Question_speech-bubble__unwa5 .Question_text__bWsL2 {
    color: #ffffff;
    z-index: 10;
    position: relative; }

.Question_speech-bubble__unwa5:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 10px;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #5f5f5f;
  border-bottom: 0;
  border-left: 0;
  margin-left: -5px;
  margin-bottom: -10px; }

.Question_hidden__15jEN {
  -webkit-animation: Question_hiddenAni__2eLUi 1s;
          animation: Question_hiddenAni__2eLUi 1s;
  opacity: 0; }

.Question_show__3G_jK {
  -webkit-animation: Question_showAni__vs81- 1s;
          animation: Question_showAni__vs81- 1s;
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

@-webkit-keyframes Question_showAni__vs81- {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, 10px);
            transform: translate(0, 10px); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes Question_showAni__vs81- {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, 10px);
            transform: translate(0, 10px); }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@-webkit-keyframes Question_hiddenAni__2eLUi {
  0% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate(0, 10px);
            transform: translate(0, 10px); } }

@keyframes Question_hiddenAni__2eLUi {
  0% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate(0, 10px);
            transform: translate(0, 10px); } }

.Info_title__1pFaU {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 15px; }

.Info_example__1cp-r {
  padding-bottom: 5px;
  margin-bottom: 15px !important;
  border-bottom: 1px solid #e1e1e1; }

.Info_content__1d4se {
  width: 100%;
  color: #7c7c7c;
  margin-bottom: 15px; }
  .Info_content__1d4se .Info_head__3A2_K,
  .Info_content__1d4se .Info_top__3rCW- {
    font-size: 1.2em; }
  .Info_content__1d4se .Info_bottom__M7Ls9 {
    margin-top: 5px;
    color: #7f7f7f; }

.Info_text__3n8DK {
  margin-left: 5px; }

.Info_red__3NlZA {
  color: #ff6e6e; }

.Info_green__38I_u {
  color: #ffca2e; }

.Info_add-block__8gLCY {
  margin-top: 20px; }
  .Info_add-block__8gLCY .Info_add__2GfFp {
    font-size: 0.9em;
    margin: 5px 0;
    color: #ccc; }
    .Info_add-block__8gLCY .Info_add__2GfFp b {
      color: #909090; }

.Info_img__3DqIa {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0; }
  .Info_img__3DqIa img {
    width: 80%; }

.Upload_header__2OW7R {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .Upload_header__2OW7R .Upload_title__wko6u {
    margin-bottom: 8px; }
    .Upload_header__2OW7R .Upload_title__wko6u .Upload_main__2EXEn {
      font-size: 2.5em;
      font-weight: 500;
      color: #3baad0; }
    .Upload_header__2OW7R .Upload_title__wko6u .Upload_desc__2UVec {
      color: #a0a0a0; }
  .Upload_header__2OW7R .Upload_button-box__26fHc {
    display: flex;
    align-items: center; }
  .Upload_header__2OW7R .Upload_sample-btn__TmFLk {
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: 0.7;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    margin-left: 10px; }
    .Upload_header__2OW7R .Upload_sample-btn__TmFLk:hover {
      opacity: 1; }
    .Upload_header__2OW7R .Upload_sample-btn__TmFLk .Upload_sample-text__3Ph7q {
      color: #54afed;
      font-size: 0.9em;
      margin-right: 3px; }
    .Upload_header__2OW7R .Upload_sample-btn__TmFLk .Upload_sample__CuxVU {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; }
    .Upload_header__2OW7R .Upload_sample-btn__TmFLk .Upload_circle-wrapper__IAIEy {
      position: absolute;
      width: 15px;
      height: 30px;
      top: 0;
      overflow: hidden; }
      .Upload_header__2OW7R .Upload_sample-btn__TmFLk .Upload_circle-wrapper__IAIEy.Upload_wrapper-right__15O-J {
        right: 0; }
      .Upload_header__2OW7R .Upload_sample-btn__TmFLk .Upload_circle-wrapper__IAIEy.Upload_wrapper-left__1bZNG {
        left: 0; }
      .Upload_header__2OW7R .Upload_sample-btn__TmFLk .Upload_circle-wrapper__IAIEy .Upload_circle-whole__S81Ev {
        width: 28px;
        height: 28px;
        border: 2px solid transparent;
        border-radius: 50%;
        position: absolute;
        top: 0;
        -webkit-transform: rotate(-135deg);
                transform: rotate(-135deg); }
        .Upload_header__2OW7R .Upload_sample-btn__TmFLk .Upload_circle-wrapper__IAIEy .Upload_circle-whole__S81Ev.Upload_circle-right__cyWkM {
          border-top: 2px solid #54afed;
          border-right: 2px solid #54afed;
          right: 1px; }
          .Upload_header__2OW7R .Upload_sample-btn__TmFLk .Upload_circle-wrapper__IAIEy .Upload_circle-whole__S81Ev.Upload_circle-right__cyWkM.Upload_animation__17fpC {
            -webkit-animation: Upload_circleRight__ShE1i 3s linear forwards;
                    animation: Upload_circleRight__ShE1i 3s linear forwards; }
        .Upload_header__2OW7R .Upload_sample-btn__TmFLk .Upload_circle-wrapper__IAIEy .Upload_circle-whole__S81Ev.Upload_circle-left__UJwEx {
          border-bottom: 2px solid #54afed;
          border-left: 2px solid #54afed;
          left: 1px; }
          .Upload_header__2OW7R .Upload_sample-btn__TmFLk .Upload_circle-wrapper__IAIEy .Upload_circle-whole__S81Ev.Upload_circle-left__UJwEx.Upload_animation__17fpC {
            -webkit-animation: Upload_circleLeft__2OIj1 3s linear forwards;
                    animation: Upload_circleLeft__2OIj1 3s linear forwards; }
    .Upload_header__2OW7R .Upload_sample-btn__TmFLk .Upload_icon__11qHM {
      width: 20px;
      display: flex;
      align-items: center; }
      .Upload_header__2OW7R .Upload_sample-btn__TmFLk .Upload_icon__11qHM img {
        width: 100%; }

.Upload_full-item__3e32q {
  display: flex;
  justify-content: space-between; }
  .Upload_full-item__3e32q .Upload_half-item__2mZMK {
    width: 45%; }

.Upload_upload-icon__3Wgcc svg {
  font-size: 1.5em; }

.Upload_upload-icon__3Wgcc img {
  width: 5%;
  margin-bottom: 10px;
  min-width: 30px;
  opacity: 0.5; }

.Upload_upload-text__2b7_6 {
  opacity: 0.5; }

.Upload_hidden-input__24Tsw {
  display: none !important; }

@-webkit-keyframes Upload_circleRight__ShE1i {
  0% {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  50%,
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); } }

@keyframes Upload_circleRight__ShE1i {
  0% {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  50%,
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); } }

@-webkit-keyframes Upload_circleLeft__2OIj1 {
  0%,
  50% {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  100% {
    -webkit-transform: rotate(45deg); } }

@keyframes Upload_circleLeft__2OIj1 {
  0%,
  50% {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  100% {
    -webkit-transform: rotate(45deg); } }

@media screen and (max-width: 700px) {
  .Upload_header__2OW7R {
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start; }
    .Upload_header__2OW7R .Upload_button-box__26fHc {
      flex-direction: column; }
      .Upload_header__2OW7R .Upload_button-box__26fHc .Upload_sample-btn__TmFLk {
        width: 100%;
        margin-left: 0; }
  .Upload_item-title__1ppx- {
    font-size: 0.9em;
    line-height: 15px;
    margin-bottom: 10px; }
  .Upload_full-item__3e32q {
    display: block; }
    .Upload_full-item__3e32q .Upload_half-item__2mZMK {
      width: 100%; } }

.Loading_loading-box__3U26i {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  margin: 0;
  padding: 0; }

.Loading_loading-box__3U26i {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(3, 3, 3, 0.9);
  flex-wrap: wrap; }

svg {
  display: none; }

.Loading_blobs__1BwXm {
  -webkit-filter: url(#goo);
          filter: url(#goo);
  width: 300px;
  height: 300px;
  position: relative;
  overflow: hidden;
  border-radius: 70px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }
  .Loading_blobs__1BwXm .Loading_blob-center__3GEeK {
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    position: absolute;
    background: #fff200;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    -webkit-transform-origin: left top;
            transform-origin: left top;
    -webkit-transform: scale(0.9) translate(-50%, -50%);
            transform: scale(0.9) translate(-50%, -50%);
    -webkit-animation: Loading_blob-grow__37NTs linear 3.4s infinite;
            animation: Loading_blob-grow__37NTs linear 3.4s infinite;
    border-radius: 50%;
    box-shadow: 0 -10px 40px -5px #fff200; }

.Loading_blob__14K6_ {
  position: absolute;
  background: #fff200;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-animation: Loading_blobs__1BwXm ease-out 3.4s infinite;
          animation: Loading_blobs__1BwXm ease-out 3.4s infinite;
  -webkit-transform: scale(0.9) translate(-50%, -50%);
          transform: scale(0.9) translate(-50%, -50%);
  -webkit-transform-origin: center top;
          transform-origin: center top;
  opacity: 0; }
  .Loading_blob__14K6_:nth-child(1) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  .Loading_blob__14K6_:nth-child(2) {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s; }
  .Loading_blob__14K6_:nth-child(3) {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s; }
  .Loading_blob__14K6_:nth-child(4) {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s; }
  .Loading_blob__14K6_:nth-child(5) {
    -webkit-animation-delay: 1s;
            animation-delay: 1s; }

@-webkit-keyframes Loading_blobs__1BwXm {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translate(calc(-330px - 50%), -50%);
            transform: scale(0) translate(calc(-330px - 50%), -50%); }
  1% {
    opacity: 1; }
  35%,
  65% {
    opacity: 1;
    -webkit-transform: scale(0.9) translate(-50%, -50%);
            transform: scale(0.9) translate(-50%, -50%); }
  99% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: scale(0) translate(calc(330px - 50%), -50%);
            transform: scale(0) translate(calc(330px - 50%), -50%); } }

@keyframes Loading_blobs__1BwXm {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translate(calc(-330px - 50%), -50%);
            transform: scale(0) translate(calc(-330px - 50%), -50%); }
  1% {
    opacity: 1; }
  35%,
  65% {
    opacity: 1;
    -webkit-transform: scale(0.9) translate(-50%, -50%);
            transform: scale(0.9) translate(-50%, -50%); }
  99% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: scale(0) translate(calc(330px - 50%), -50%);
            transform: scale(0) translate(calc(330px - 50%), -50%); } }

@-webkit-keyframes Loading_blob-grow__37NTs {
  0%,
  39% {
    -webkit-transform: scale(0) translate(-50%, -50%);
            transform: scale(0) translate(-50%, -50%); }
  40%,
  42% {
    -webkit-transform: scale(1, 0.9) translate(-50%, -50%);
            transform: scale(1, 0.9) translate(-50%, -50%); }
  43%,
  44% {
    -webkit-transform: scale(1.2, 1.1) translate(-50%, -50%);
            transform: scale(1.2, 1.1) translate(-50%, -50%); }
  45%,
  46% {
    -webkit-transform: scale(1.3, 1.2) translate(-50%, -50%);
            transform: scale(1.3, 1.2) translate(-50%, -50%); }
  47%,
  48% {
    -webkit-transform: scale(1.4, 1.3) translate(-50%, -50%);
            transform: scale(1.4, 1.3) translate(-50%, -50%); }
  52% {
    -webkit-transform: scale(1.5, 1.4) translate(-50%, -50%);
            transform: scale(1.5, 1.4) translate(-50%, -50%); }
  54% {
    -webkit-transform: scale(1.7, 1.6) translate(-50%, -50%);
            transform: scale(1.7, 1.6) translate(-50%, -50%); }
  58% {
    -webkit-transform: scale(1.8, 1.7) translate(-50%, -50%);
            transform: scale(1.8, 1.7) translate(-50%, -50%); }
  68%,
  70% {
    -webkit-transform: scale(1.7, 1.5) translate(-50%, -50%);
            transform: scale(1.7, 1.5) translate(-50%, -50%); }
  78% {
    -webkit-transform: scale(1.6, 1.4) translate(-50%, -50%);
            transform: scale(1.6, 1.4) translate(-50%, -50%); }
  80%,
  81% {
    -webkit-transform: scale(1.5, 1.4) translate(-50%, -50%);
            transform: scale(1.5, 1.4) translate(-50%, -50%); }
  82%,
  83% {
    -webkit-transform: scale(1.4, 1.3) translate(-50%, -50%);
            transform: scale(1.4, 1.3) translate(-50%, -50%); }
  84%,
  85% {
    -webkit-transform: scale(1.3, 1.2) translate(-50%, -50%);
            transform: scale(1.3, 1.2) translate(-50%, -50%); }
  86%,
  87% {
    -webkit-transform: scale(1.2, 1.1) translate(-50%, -50%);
            transform: scale(1.2, 1.1) translate(-50%, -50%); }
  90%,
  91% {
    -webkit-transform: scale(1, 0.9) translate(-50%, -50%);
            transform: scale(1, 0.9) translate(-50%, -50%); }
  92%,
  100% {
    -webkit-transform: scale(0) translate(-50%, -50%);
            transform: scale(0) translate(-50%, -50%); } }

@keyframes Loading_blob-grow__37NTs {
  0%,
  39% {
    -webkit-transform: scale(0) translate(-50%, -50%);
            transform: scale(0) translate(-50%, -50%); }
  40%,
  42% {
    -webkit-transform: scale(1, 0.9) translate(-50%, -50%);
            transform: scale(1, 0.9) translate(-50%, -50%); }
  43%,
  44% {
    -webkit-transform: scale(1.2, 1.1) translate(-50%, -50%);
            transform: scale(1.2, 1.1) translate(-50%, -50%); }
  45%,
  46% {
    -webkit-transform: scale(1.3, 1.2) translate(-50%, -50%);
            transform: scale(1.3, 1.2) translate(-50%, -50%); }
  47%,
  48% {
    -webkit-transform: scale(1.4, 1.3) translate(-50%, -50%);
            transform: scale(1.4, 1.3) translate(-50%, -50%); }
  52% {
    -webkit-transform: scale(1.5, 1.4) translate(-50%, -50%);
            transform: scale(1.5, 1.4) translate(-50%, -50%); }
  54% {
    -webkit-transform: scale(1.7, 1.6) translate(-50%, -50%);
            transform: scale(1.7, 1.6) translate(-50%, -50%); }
  58% {
    -webkit-transform: scale(1.8, 1.7) translate(-50%, -50%);
            transform: scale(1.8, 1.7) translate(-50%, -50%); }
  68%,
  70% {
    -webkit-transform: scale(1.7, 1.5) translate(-50%, -50%);
            transform: scale(1.7, 1.5) translate(-50%, -50%); }
  78% {
    -webkit-transform: scale(1.6, 1.4) translate(-50%, -50%);
            transform: scale(1.6, 1.4) translate(-50%, -50%); }
  80%,
  81% {
    -webkit-transform: scale(1.5, 1.4) translate(-50%, -50%);
            transform: scale(1.5, 1.4) translate(-50%, -50%); }
  82%,
  83% {
    -webkit-transform: scale(1.4, 1.3) translate(-50%, -50%);
            transform: scale(1.4, 1.3) translate(-50%, -50%); }
  84%,
  85% {
    -webkit-transform: scale(1.3, 1.2) translate(-50%, -50%);
            transform: scale(1.3, 1.2) translate(-50%, -50%); }
  86%,
  87% {
    -webkit-transform: scale(1.2, 1.1) translate(-50%, -50%);
            transform: scale(1.2, 1.1) translate(-50%, -50%); }
  90%,
  91% {
    -webkit-transform: scale(1, 0.9) translate(-50%, -50%);
            transform: scale(1, 0.9) translate(-50%, -50%); }
  92%,
  100% {
    -webkit-transform: scale(0) translate(-50%, -50%);
            transform: scale(0) translate(-50%, -50%); } }

.Loading_loading-gauge__J1sFX {
  width: 100%;
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #ffdd00; }

.Loading_gauge__V05te {
  height: 100%;
  transform: translate(0, 0);
  transition: all 0.3s;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s; }

.Loading_title__2P05g {
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 2em; }

.Login_login-container__V_oGt {
  background: #2cacd1;
  background: -webkit-linear-gradient(312deg, #2cacd1 0%, #2c65b1 100%);
  background: linear-gradient(138deg, #2cacd1 0%, #2c65b1 100%);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.Login_title-box__1eFBk {
  text-align: center;
  margin: 15px 0; }
  .Login_title-box__1eFBk .Login_title__1lY0k {
    color: #ffffff;
    font-size: 1.8em;
    font-weight: bold; }

.Login_login-box__3gp63 {
  position: absolute;
  top: 20%;
  left: 200px;
  margin: 50px 0; }
  .Login_login-box__3gp63 .Login_login-form__2VaNY {
    width: 300px; }
  .Login_login-box__3gp63 .Login_title__1lY0k {
    color: #ffffff;
    font-size: 6em;
    font-weight: 100; }
  .Login_login-box__3gp63 .Login_sub-desc__3d7Xu {
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 30px; }
  .Login_login-box__3gp63 .Login_input-box__DNMnI {
    width: 100%; }
    .Login_login-box__3gp63 .Login_input-box__DNMnI.Login_last-input__1TR6S {
      margin-bottom: 0 !important; }
    .Login_login-box__3gp63 .Login_input-box__DNMnI svg {
      color: rgba(255, 255, 255, 0.55); }
    .Login_login-box__3gp63 .Login_input-box__DNMnI input {
      background-color: transparent !important;
      border-radius: 0px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      -ms-border-radius: 0px;
      -o-border-radius: 0px;
      color: #ffffff;
      border: 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
      .Login_login-box__3gp63 .Login_input-box__DNMnI input:focus {
        background-color: transparent; }

.Login_button-box__34wDH {
  display: flex;
  align-items: center;
  justify-content: center; }

.Login_login-button__3ISnZ {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  cursor: pointer; }
  .Login_login-button__3ISnZ svg {
    height: 35px;
    width: 90px;
    fill: none;
    stroke-width: 3;
    display: block; }
    .Login_login-button__3ISnZ svg .Login_o1__1rSeg {
      stroke: rgba(255, 255, 255, 0.5);
      fill: transparent;
      -webkit-transition: all 1s ease-in-out;
      transition: all 1s ease-in-out; }
    .Login_login-button__3ISnZ svg .Login_o2__3nd_f {
      stroke: white;
      stroke-dasharray: 20 420;
      stroke-dashoffset: 20;
      -webkit-transition: all 1s ease-in-out;
      transition: all 1s ease-in-out; }
  .Login_login-button__3ISnZ span {
    position: absolute;
    margin: auto 0;
    text-transform: uppercase;
    font-size: 0.9em;
    color: #ffffff; }
  .Login_login-button__3ISnZ:hover .Login_o2__3nd_f {
    stroke-dashoffset: -420; }

.Login_circle__2kOvJ {
  width: 800px;
  height: 800px;
  position: absolute;
  top: 30px;
  right: 30px; }
  .Login_circle__2kOvJ img {
    width: 100%;
    height: 100%; }

.Login_project-info__mvyIZ {
  position: absolute;
  top: 150px;
  right: 250px;
  color: #ffffff;
  padding: 2px 12px;
  border-left: 1.5px solid rgba(255, 255, 255, 0.8);
  opacity: 1; }
  .Login_project-info__mvyIZ .Login_desc__ITAMJ {
    margin-bottom: 10px; }
  .Login_project-info__mvyIZ .Login_small__2eQs0 {
    font-size: 0.9em;
    opacity: 0.8; }
  .Login_project-info__mvyIZ .Login_email__2M9mO {
    font-size: 0.9em;
    text-decoration: underline;
    opacity: 0.8; }
  .Login_project-info__mvyIZ .Login_sns-box__PPKyV {
    margin-top: 10px; }
    .Login_project-info__mvyIZ .Login_sns-box__PPKyV a {
      color: #ffffff; }
    .Login_project-info__mvyIZ .Login_sns-box__PPKyV * {
      color: #ffffff;
      font-size: 1.2em;
      cursor: pointer; }

@media screen and (max-width: 1000px) {
  .Login_login-container__V_oGt {
    display: flex;
    align-items: center;
    justify-content: center; }
  .Login_login-box__3gp63 {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .Login_login-box__3gp63 .Login_title__1lY0k {
      font-size: 4em; }
  .Login_project-info__mvyIZ {
    display: none; }
  .Login_circle__2kOvJ {
    top: auto;
    bottom: -100px;
    width: 400px;
    height: 400px;
    right: -100px; }
  .Login_sub-desc__3d7Xu {
    text-align: center;
    padding: 0 20px; } }

.Login_for-daeun__1FKpd {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #ffffff;
  font-weight: 200;
  opacity: 0.3; }

.Login_without-login__3WheH {
  font-size: 0.9em;
  margin-top: 5px;
  margin-bottom: 30px;
  color: #ffffff;
  cursor: pointer;
  opacity: 0.5;
  -webkit-transition: all 0.4s;
  transition: all 0.4s; }
  .Login_without-login__3WheH:hover {
    opacity: 1; }

.Analysis_summary__fPrWl .Analysis_title-box__3MFSB {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px; }
  .Analysis_summary__fPrWl .Analysis_title-box__3MFSB .Analysis_title__1WYN1 {
    font-size: 2.5em;
    font-weight: 500;
    margin-bottom: 8px;
    color: #3baad0; }
  .Analysis_summary__fPrWl .Analysis_title-box__3MFSB .Analysis_download-box__3i_ql {
    display: flex;
    align-items: center; }
    .Analysis_summary__fPrWl .Analysis_title-box__3MFSB .Analysis_download-box__3i_ql .Analysis_btn-guide__qgC22 {
      opacity: 0.4;
      color: #929292;
      margin-left: 8px;
      font-weight: normal;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      font-size: 12px; }
      .Analysis_summary__fPrWl .Analysis_title-box__3MFSB .Analysis_download-box__3i_ql .Analysis_btn-guide__qgC22:hover {
        opacity: 1; }
    .Analysis_summary__fPrWl .Analysis_title-box__3MFSB .Analysis_download-box__3i_ql .Analysis_btn-box__3MbPR {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.3em;
      font-weight: bold;
      cursor: pointer;
      margin: 0 10px; }
      .Analysis_summary__fPrWl .Analysis_title-box__3MFSB .Analysis_download-box__3i_ql .Analysis_btn-box__3MbPR.Analysis_excel-btn__cA_Ot {
        color: #007337; }
      .Analysis_summary__fPrWl .Analysis_title-box__3MFSB .Analysis_download-box__3i_ql .Analysis_btn-box__3MbPR.Analysis_indel-btn__2Q4cK {
        color: #ff4e5d; }
      .Analysis_summary__fPrWl .Analysis_title-box__3MFSB .Analysis_download-box__3i_ql .Analysis_btn-box__3MbPR.Analysis_report-btn__3nRVM {
        color: #ffd64e; }

.Analysis_analysis-container__En6rG {
  margin-bottom: 50px; }

.Analysis_analysis-id__25hKe {
  font-size: 1.4em;
  font-weight: bold;
  color: #ffffff;
  border-bottom: 1px solid #d9d9d9;
  padding: 10px;
  background-color: #545454; }

.Analysis_sum-title__358gD {
  padding-bottom: 5px;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between; }
  .Analysis_sum-title__358gD .Analysis_sum-title-text__2-gZu {
    font-size: 1.1em;
    color: #424242; }

.Analysis_color-wrapper__1bKjY {
  display: flex; }
  .Analysis_color-wrapper__1bKjY .Analysis_color-box__3UJ0W {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px; }
    .Analysis_color-wrapper__1bKjY .Analysis_color-box__3UJ0W .Analysis_bg__3zfPi {
      width: 10px;
      height: 10px;
      margin-right: 5px; }
    .Analysis_color-wrapper__1bKjY .Analysis_color-box__3UJ0W .Analysis_text__21YML {
      font-size: 0.8em; }
    .Analysis_color-wrapper__1bKjY .Analysis_color-box__3UJ0W.Analysis_origin-val__2n28h .Analysis_bg__3zfPi {
      background-color: #ffc352; }
    .Analysis_color-wrapper__1bKjY .Analysis_color-box__3UJ0W.Analysis_target-val__1SsT1 .Analysis_bg__3zfPi {
      background-color: #e54b4b; }
    .Analysis_color-wrapper__1bKjY .Analysis_color-box__3UJ0W.Analysis_sub-val__2GXVa .Analysis_bg__3zfPi {
      background-color: #61bfad; }
    .Analysis_color-wrapper__1bKjY .Analysis_color-box__3UJ0W.Analysis_deletion-val__2zbdt .Analysis_bg__3zfPi {
      background-color: #91c6ff; }
    .Analysis_color-wrapper__1bKjY .Analysis_color-box__3UJ0W.Analysis_insertion-val__33TQI .Analysis_bg__3zfPi {
      background-color: #bc9eec; }

.Analysis_summary-item__2X3gw {
  padding: 15px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 30px; }
  .Analysis_summary-item__2X3gw.Analysis_fix-sequence__2zNAD {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
    padding: 5px 14px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transition: opacity 0.4s, top 0.4s;
    transition: opacity 0.4s, top 0.4s; }
    .Analysis_summary-item__2X3gw.Analysis_fix-sequence__2zNAD .Analysis_fix-wrapper__ERxD8 {
      width: 85%;
      max-width: 1286px;
      margin: auto;
      padding: 10px;
      box-shadow: -5px -11px 58px 6px rgba(0, 0, 0, 0.39);
      background-color: #514f4f;
      color: #ffffff; }
      .Analysis_summary-item__2X3gw.Analysis_fix-sequence__2zNAD .Analysis_fix-wrapper__ERxD8 .Analysis_sum-title-text__2-gZu {
        color: #ffffff; }
    .Analysis_summary-item__2X3gw.Analysis_fix-sequence__2zNAD.Analysis_close__1Sz4U {
      opacity: 0;
      top: -200px; }

.Analysis_sum-info__2O-_N {
  display: flex;
  align-items: center; }
  .Analysis_sum-info__2O-_N .Analysis_info__19Ulu {
    display: flex;
    align-items: center;
    margin-right: 5px; }
    .Analysis_sum-info__2O-_N .Analysis_info__19Ulu .Analysis_text__21YML {
      font-size: 0.9em; }
    .Analysis_sum-info__2O-_N .Analysis_info__19Ulu .Analysis_info-icon__1ft3r {
      width: 10px;
      height: 10px;
      margin-right: 5px; }
    .Analysis_sum-info__2O-_N .Analysis_info__19Ulu .Analysis_blue__2tm6j {
      background-color: #00a2c2; }
    .Analysis_sum-info__2O-_N .Analysis_info__19Ulu .Analysis_green__1K-Fa {
      background-color: #ff9d1c; }
    .Analysis_sum-info__2O-_N .Analysis_info__19Ulu .Analysis_red___r5ro {
      background-color: #c21700; }

.Analysis_sum-val__2sBIP {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 10px 0; }
  .Analysis_sum-val__2sBIP.Analysis_wt-wrapper__1LgSb {
    display: block;
    word-break: break-all;
    text-align: center; }
  .Analysis_sum-val__2sBIP .Analysis_sum-primerSeq__3HWP6 {
    color: #00a2c2;
    font-weight: bold; }
  .Analysis_sum-val__2sBIP .Analysis_sum-rgenSeq__3O_su {
    color: #ff9d1c;
    font-weight: bold; }
  .Analysis_sum-val__2sBIP .Analysis_sum-coreSeq__1R_Oc {
    color: #c21700;
    font-weight: bold; }

.Analysis_result-item__12cyF {
  flex: 1 1;
  text-align: center;
  margin: 0 15px; }
  .Analysis_result-item__12cyF .Analysis_result-title__1CHrw {
    font-size: 1em;
    color: #9c9c9c;
    margin-bottom: 5px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .Analysis_result-item__12cyF .Analysis_result-val__1iWPU {
    font-size: 1.4em;
    font-weight: bold;
    color: #5c5c5c; }

.value-wrapper {
  display: flex;
  flex-wrap: wrap; }
  .value-wrapper .graphic {
    letter-spacing: 5.15px; }
  .value-wrapper .value-box {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .value-wrapper .value-box .value {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.8em; }
    .value-wrapper .value-box .target-val {
      font-weight: bold;
      background-color: #e54b4b;
      color: #ffffff; }
    .value-wrapper .value-box .insertion-val {
      font-weight: bold;
      background-color: #bc9eec;
      color: #ffffff; }
    .value-wrapper .value-box .deletion-val {
      font-weight: bold;
      background-color: #91c6ff;
      color: #ffffff; }
    .value-wrapper .value-box .sub-val {
      font-weight: bold;
      background-color: #61bfad;
      color: #ffffff; }

.Analysis_char-box__2uKX2 {
  flex: 1 1; }
  .Analysis_char-box__2uKX2.Analysis_origin-char__1B3vn {
    text-align: center;
    font-weight: bold; }
  .Analysis_char-box__2uKX2 .Analysis_char__4kiQ9 {
    text-align: center;
    cursor: pointer;
    position: relative;
    padding: 3px 0;
    width: 100%;
    height: 100%;
    z-index: 100; }
    .Analysis_char-box__2uKX2 .Analysis_char__4kiQ9.Analysis_char-title__7E9Yq {
      font-weight: bold; }
    .Analysis_char-box__2uKX2 .Analysis_char__4kiQ9 .Analysis_bg__3zfPi {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1; }
    .Analysis_char-box__2uKX2 .Analysis_char__4kiQ9 span {
      z-index: 100; }
    .Analysis_char-box__2uKX2 .Analysis_char__4kiQ9.Analysis_target-val__1SsT1 .Analysis_bg__3zfPi {
      background-color: #e54b4b; }
    .Analysis_char-box__2uKX2 .Analysis_char__4kiQ9.Analysis_origin-val__2n28h .Analysis_bg__3zfPi {
      background-color: #ffc352; }
    .Analysis_char-box__2uKX2 .Analysis_char__4kiQ9.Analysis_changed__2UHn- span {
      color: #ffffff; }
    .Analysis_char-box__2uKX2 .Analysis_char__4kiQ9.Analysis_sub-val__2GXVa .Analysis_bg__3zfPi {
      background-color: #61bfad; }

.Analysis_change-seq__3hpHh {
  display: flex;
  align-items: center; }
  .Analysis_change-seq__3hpHh .Analysis_seq__3yuaw {
    font-size: 3em;
    margin: 0px 10px;
    font-weight: bold; }
  .Analysis_change-seq__3hpHh svg {
    font-size: 2em;
    margin: 0 20px; }

.Analysis_change-result__16YVA {
  width: 100%;
  text-align: center;
  font-size: 2.5em;
  font-weight: bold;
  cursor: pointer; }

.Analysis_total__1N0Je {
  width: 100%;
  text-align: center;
  font-size: 2em; }

.Analysis_excel-dwn__3lUdR {
  cursor: pointer;
  font-size: 1.2em; }
  .Analysis_excel-dwn__3lUdR * {
    font-size: 1em; }

@media screen and (max-width: 1000px) {
  .Analysis_sum-title__358gD {
    flex-direction: column; }
    .Analysis_sum-title__358gD .Analysis_sum-title-text__2-gZu {
      margin-bottom: 5px; }
    .Analysis_sum-title__358gD .Analysis_sum-info__2O-_N {
      flex-direction: column;
      align-items: flex-start; }
  .Analysis_title-box__3MFSB {
    flex-direction: column; }
    .Analysis_title-box__3MFSB .Analysis_download-box__3i_ql {
      flex-direction: column; }
      .Analysis_title-box__3MFSB .Analysis_download-box__3i_ql .Analysis_btn-box__3MbPR {
        width: 100%;
        justify-content: flex-start !important;
        margin-bottom: 10px !important; }
  .Analysis_sum-val__2sBIP {
    flex-direction: column; }
    .Analysis_sum-val__2sBIP * {
      word-break: break-all; } }

.Signup_signup-container__ZWyUQ {
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  background: #2cacd1;
  background: -webkit-linear-gradient(312deg, #2cacd1 0%, #2c65b1 100%);
  background: linear-gradient(138deg, #2cacd1 0%, #2c65b1 100%);
  display: flex;
  align-items: center;
  justify-content: center; }
  .Signup_signup-container__ZWyUQ label {
    color: #ffffff !important; }
  .Signup_signup-container__ZWyUQ .Signup_signup-form__3JPl-,
  .Signup_signup-container__ZWyUQ .Signup_confirm-form__1ixry {
    width: 40%;
    max-width: 430px; }
    .Signup_signup-container__ZWyUQ .Signup_signup-form__3JPl- input,
    .Signup_signup-container__ZWyUQ .Signup_confirm-form__1ixry input {
      background-color: transparent !important;
      border-radius: 0px;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      -ms-border-radius: 0px;
      -o-border-radius: 0px;
      color: #ffffff;
      border: 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
      .Signup_signup-container__ZWyUQ .Signup_signup-form__3JPl- input:focus,
      .Signup_signup-container__ZWyUQ .Signup_confirm-form__1ixry input:focus {
        background-color: transparent; }
    .Signup_signup-container__ZWyUQ .Signup_signup-form__3JPl- .ant-input-password-icon,
    .Signup_signup-container__ZWyUQ .Signup_confirm-form__1ixry .ant-input-password-icon {
      color: rgba(255, 255, 255, 0.55); }
  .Signup_signup-container__ZWyUQ .ant-row.ant-form-item {
    margin-bottom: 5px; }
  .Signup_signup-container__ZWyUQ .Signup_notification__1YQhD {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9em;
    margin-bottom: 10px; }
    .Signup_signup-container__ZWyUQ .Signup_notification__1YQhD .Signup_login-point__3Bf23 {
      text-decoration: underline;
      cursor: pointer;
      display: inline-block;
      margin: 0 5px; }

.Signup_login-button__Jqw44 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  cursor: pointer; }
  .Signup_login-button__Jqw44 svg {
    height: 35px;
    width: 90px;
    fill: none;
    stroke-width: 3;
    display: block; }
    .Signup_login-button__Jqw44 svg .Signup_o1__3QyMn {
      stroke: rgba(255, 255, 255, 0.5);
      fill: transparent;
      -webkit-transition: all 1s ease-in-out;
      transition: all 1s ease-in-out; }
    .Signup_login-button__Jqw44 svg .Signup_o2__2SiNh {
      stroke: white;
      stroke-dasharray: 20 420;
      stroke-dashoffset: 20;
      -webkit-transition: all 1s ease-in-out;
      transition: all 1s ease-in-out; }
  .Signup_login-button__Jqw44 span {
    position: absolute;
    margin: auto 0;
    text-transform: uppercase;
    font-size: 0.9em;
    color: #ffffff; }
  .Signup_login-button__Jqw44:hover .Signup_o2__2SiNh {
    stroke-dashoffset: -420; }

.Signup_sent-noti__2QM86 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.9em; }
  .Signup_sent-noti__2QM86 .Signup_point-send__2tPgL {
    color: #ffffff;
    font-size: 1.3em;
    text-decoration: underline; }

.List_list-wrapper__rvd35 .List_header__9UgI3 {
  margin-bottom: 20px; }
  .List_list-wrapper__rvd35 .List_header__9UgI3 .List_title__rqZIM {
    font-size: 2.5em;
    font-weight: 500;
    margin-bottom: 8px;
    color: #3baad0; }

.List_list-wrapper__rvd35 .List_button-box__25cov {
  margin-top: 10px; }

.List_no-data__2hXUG {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.5; }
  .List_no-data__2hXUG .List_img__3307_ {
    width: 150px;
    opacity: 0.8;
    margin-bottom: 10px; }
    .List_no-data__2hXUG .List_img__3307_ img {
      width: 100%; }
  .List_no-data__2hXUG .List_text__1_8XD {
    font-size: 1.5em;
    color: #242424; }

.Hidden_hidden-wrapper__1kBrE {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #04001b; }

.Hidden_logo-box__mZZBq {
  cursor: pointer; }

@-webkit-keyframes Hidden_black-hole__3efwY {
  0% {
    -webkit-transform: scale(1) translate3d(75px, 0, 0);
            transform: scale(1) translate3d(75px, 0, 0); }
  50% {
    -webkit-transform: scale(4) translate3d(55px, 0, 0);
            transform: scale(4) translate3d(55px, 0, 0); }
  100% {
    -webkit-transform: scale(1) translate3d(75px, 0, 0);
            transform: scale(1) translate3d(75px, 0, 0); } }

@keyframes Hidden_black-hole__3efwY {
  0% {
    -webkit-transform: scale(1) translate3d(75px, 0, 0);
            transform: scale(1) translate3d(75px, 0, 0); }
  50% {
    -webkit-transform: scale(4) translate3d(55px, 0, 0);
            transform: scale(4) translate3d(55px, 0, 0); }
  100% {
    -webkit-transform: scale(1) translate3d(75px, 0, 0);
            transform: scale(1) translate3d(75px, 0, 0); } }

@-webkit-keyframes Hidden_black-hole-rotate__LmDGy {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg) skew(3deg);
            transform: translate(-50%, -50%) rotate(0deg) skew(3deg); }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(180deg) skew(0deg);
            transform: translate(-50%, -50%) rotate(180deg) skew(0deg); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg) skew(3deg);
            transform: translate(-50%, -50%) rotate(360deg) skew(3deg); } }

@keyframes Hidden_black-hole-rotate__LmDGy {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg) skew(3deg);
            transform: translate(-50%, -50%) rotate(0deg) skew(3deg); }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(180deg) skew(0deg);
            transform: translate(-50%, -50%) rotate(180deg) skew(0deg); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg) skew(3deg);
            transform: translate(-50%, -50%) rotate(360deg) skew(3deg); } }

.Hidden_black-hole__3efwY {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 20;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  padding: 0;
  box-shadow: 0 0 30px 0 #0000ff inset; }
  .Hidden_black-hole__3efwY .Hidden_gooey-container__2ufqI {
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(0deg) skew(5deg);
            transform: translate(-50%, -50%) rotate(0deg) skew(5deg);
    -webkit-filter: url(#gooey-black-hole);
            filter: url(#gooey-black-hole);
    width: 300px;
    height: 300px;
    padding: 0;
    margin: 0;
    box-shadow: 0 0 0 22px #9900ff inset;
    -webkit-animation: Hidden_black-hole-rotate__LmDGy 5s ease infinite;
            animation: Hidden_black-hole-rotate__LmDGy 5s ease infinite; }
    .Hidden_black-hole__3efwY .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      text-align: right; }
      .Hidden_black-hole__3efwY .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:before {
        content: "";
        display: inline-block;
        background: #ff4081;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        transform: scale(1) translate3d(75px, 0, 0);
        box-shadow: 0 0 10px 0 #0000ff inset, 0 0 10px 0 #0000ff inset;
        -webkit-transform: scale(1) translate3d(75px, 0, 0);
        -moz-transform: scale(1) translate3d(75px, 0, 0);
        -ms-transform: scale(1) translate3d(75px, 0, 0);
        -o-transform: scale(1) translate3d(75px, 0, 0); }
    .Hidden_black-hole__3efwY .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(1) {
      -webkit-transform: translate(-50%, -50%) rotate(60deg);
              transform: translate(-50%, -50%) rotate(60deg); }
    .Hidden_black-hole__3efwY .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(1):before {
      -webkit-animation: Hidden_black-hole__3efwY 1s ease 0.5s infinite;
              animation: Hidden_black-hole__3efwY 1s ease 0.5s infinite; }
    .Hidden_black-hole__3efwY .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(2) {
      -webkit-transform: translate(-50%, -50%) rotate(120deg);
              transform: translate(-50%, -50%) rotate(120deg); }
    .Hidden_black-hole__3efwY .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(2):before {
      -webkit-animation: Hidden_black-hole__3efwY 2s ease 1s infinite;
              animation: Hidden_black-hole__3efwY 2s ease 1s infinite; }
    .Hidden_black-hole__3efwY .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(3) {
      -webkit-transform: translate(-50%, -50%) rotate(180deg);
              transform: translate(-50%, -50%) rotate(180deg); }
    .Hidden_black-hole__3efwY .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(3):before {
      -webkit-animation: Hidden_black-hole__3efwY 3s ease 1.5s infinite;
              animation: Hidden_black-hole__3efwY 3s ease 1.5s infinite; }
    .Hidden_black-hole__3efwY .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(4) {
      -webkit-transform: translate(-50%, -50%) rotate(240deg);
              transform: translate(-50%, -50%) rotate(240deg); }
    .Hidden_black-hole__3efwY .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(4):before {
      -webkit-animation: Hidden_black-hole__3efwY 4s ease 2s infinite;
              animation: Hidden_black-hole__3efwY 4s ease 2s infinite; }
    .Hidden_black-hole__3efwY .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(5) {
      -webkit-transform: translate(-50%, -50%) rotate(300deg);
              transform: translate(-50%, -50%) rotate(300deg); }
    .Hidden_black-hole__3efwY .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(5):before {
      -webkit-animation: Hidden_black-hole__3efwY 5s ease 2.5s infinite;
              animation: Hidden_black-hole__3efwY 5s ease 2.5s infinite; }
    .Hidden_black-hole__3efwY .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(6) {
      -webkit-transform: translate(-50%, -50%) rotate(360deg);
              transform: translate(-50%, -50%) rotate(360deg); }
    .Hidden_black-hole__3efwY .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(6):before {
      -webkit-animation: Hidden_black-hole__3efwY 6s ease 3s infinite;
              animation: Hidden_black-hole__3efwY 6s ease 3s infinite; }

@-webkit-keyframes Hidden_plasma-2__cZZjs {
  0% {
    -webkit-transform: scale(1) translate3d(60px, 0, 0);
            transform: scale(1) translate3d(60px, 0, 0); }
  50% {
    -webkit-transform: scale(2) translate3d(-60px, 0, 0);
            transform: scale(2) translate3d(-60px, 0, 0); }
  100% {
    -webkit-transform: scale(1) translate3d(60px, 0, 0);
            transform: scale(1) translate3d(60px, 0, 0); } }

@keyframes Hidden_plasma-2__cZZjs {
  0% {
    -webkit-transform: scale(1) translate3d(60px, 0, 0);
            transform: scale(1) translate3d(60px, 0, 0); }
  50% {
    -webkit-transform: scale(2) translate3d(-60px, 0, 0);
            transform: scale(2) translate3d(-60px, 0, 0); }
  100% {
    -webkit-transform: scale(1) translate3d(60px, 0, 0);
            transform: scale(1) translate3d(60px, 0, 0); } }

@-webkit-keyframes Hidden_plasma-2-rotate__2RpFr {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes Hidden_plasma-2-rotate__2RpFr {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg); } }

.Hidden_plasma-2__cZZjs {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 20;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  padding: 0; }
  .Hidden_plasma-2__cZZjs .Hidden_gooey-container__2ufqI {
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-filter: url(#gooey-plasma-2);
            filter: url(#gooey-plasma-2);
    width: 300px;
    height: 300px;
    padding: 0;
    margin: 0;
    box-shadow: 0 0 0 20px #9900ff inset;
    -webkit-animation: Hidden_plasma-2-rotate__2RpFr 5s linear infinite;
            animation: Hidden_plasma-2-rotate__2RpFr 5s linear infinite; }
    .Hidden_plasma-2__cZZjs .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      text-align: right; }
      .Hidden_plasma-2__cZZjs .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:before {
        content: "";
        display: inline-block;
        background: #ff4081;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        -webkit-transform: scale(1) translate3d(75px, 0, 0);
                transform: scale(1) translate3d(75px, 0, 0);
        box-shadow: 0 0 10px 5px #0000ff inset, 0 0 10px 0 #0000ff inset; }
    .Hidden_plasma-2__cZZjs .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(1) {
      -webkit-transform: translate(-50%, -50%) rotate(60deg);
              transform: translate(-50%, -50%) rotate(60deg); }
    .Hidden_plasma-2__cZZjs .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(1):before {
      -webkit-animation: Hidden_plasma-2__cZZjs 2.5s ease 0.5s infinite;
              animation: Hidden_plasma-2__cZZjs 2.5s ease 0.5s infinite; }
    .Hidden_plasma-2__cZZjs .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(2) {
      -webkit-transform: translate(-50%, -50%) rotate(120deg);
              transform: translate(-50%, -50%) rotate(120deg); }
    .Hidden_plasma-2__cZZjs .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(2):before {
      -webkit-animation: Hidden_plasma-2__cZZjs 5s ease 0.25s infinite;
              animation: Hidden_plasma-2__cZZjs 5s ease 0.25s infinite; }
    .Hidden_plasma-2__cZZjs .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(3) {
      -webkit-transform: translate(-50%, -50%) rotate(180deg);
              transform: translate(-50%, -50%) rotate(180deg); }
    .Hidden_plasma-2__cZZjs .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(3):before {
      -webkit-animation: Hidden_plasma-2__cZZjs 7.5s ease 0.16667s infinite;
              animation: Hidden_plasma-2__cZZjs 7.5s ease 0.16667s infinite; }
    .Hidden_plasma-2__cZZjs .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(4) {
      -webkit-transform: translate(-50%, -50%) rotate(240deg);
              transform: translate(-50%, -50%) rotate(240deg); }
    .Hidden_plasma-2__cZZjs .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(4):before {
      -webkit-animation: Hidden_plasma-2__cZZjs 10s ease 0.125s infinite;
              animation: Hidden_plasma-2__cZZjs 10s ease 0.125s infinite; }
    .Hidden_plasma-2__cZZjs .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(5) {
      -webkit-transform: translate(-50%, -50%) rotate(300deg);
              transform: translate(-50%, -50%) rotate(300deg); }
    .Hidden_plasma-2__cZZjs .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(5):before {
      -webkit-animation: Hidden_plasma-2__cZZjs 12.5s ease 0.1s infinite;
              animation: Hidden_plasma-2__cZZjs 12.5s ease 0.1s infinite; }
    .Hidden_plasma-2__cZZjs .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(6) {
      -webkit-transform: translate(-50%, -50%) rotate(360deg);
              transform: translate(-50%, -50%) rotate(360deg); }
    .Hidden_plasma-2__cZZjs .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(6):before {
      -webkit-animation: Hidden_plasma-2__cZZjs 15s ease 0.08333s infinite;
              animation: Hidden_plasma-2__cZZjs 15s ease 0.08333s infinite; }

@-webkit-keyframes Hidden_fill-level__2gQ54 {
  0% {
    -webkit-transform: translate3d(-413px, -53px, 0) rotate(45deg);
            transform: translate3d(-413px, -53px, 0) rotate(45deg); }
  15% {
    -webkit-transform: translate3d(-393px, -73px, 0) rotate(25deg);
            transform: translate3d(-393px, -73px, 0) rotate(25deg); }
  30% {
    -webkit-transform: translate3d(-343px, -123px, 0) rotate(45deg);
            transform: translate3d(-343px, -123px, 0) rotate(45deg); }
  45% {
    -webkit-transform: translate3d(-283px, -183px, 0) rotate(55deg);
            transform: translate3d(-283px, -183px, 0) rotate(55deg); }
  60% {
    -webkit-transform: translate3d(-263px, -203px, 0) rotate(45deg);
            transform: translate3d(-263px, -203px, 0) rotate(45deg); }
  75% {
    -webkit-transform: translate3d(-243px, -223px, 0) rotate(35deg);
            transform: translate3d(-243px, -223px, 0) rotate(35deg); }
  90% {
    -webkit-transform: translate3d(-223px, -243px, 0) rotate(45deg);
            transform: translate3d(-223px, -243px, 0) rotate(45deg); }
  100% {
    -webkit-transform: translate3d(-203px, -263px, 0) rotate(55deg);
            transform: translate3d(-203px, -263px, 0) rotate(55deg); } }

@keyframes Hidden_fill-level__2gQ54 {
  0% {
    -webkit-transform: translate3d(-413px, -53px, 0) rotate(45deg);
            transform: translate3d(-413px, -53px, 0) rotate(45deg); }
  15% {
    -webkit-transform: translate3d(-393px, -73px, 0) rotate(25deg);
            transform: translate3d(-393px, -73px, 0) rotate(25deg); }
  30% {
    -webkit-transform: translate3d(-343px, -123px, 0) rotate(45deg);
            transform: translate3d(-343px, -123px, 0) rotate(45deg); }
  45% {
    -webkit-transform: translate3d(-283px, -183px, 0) rotate(55deg);
            transform: translate3d(-283px, -183px, 0) rotate(55deg); }
  60% {
    -webkit-transform: translate3d(-263px, -203px, 0) rotate(45deg);
            transform: translate3d(-263px, -203px, 0) rotate(45deg); }
  75% {
    -webkit-transform: translate3d(-243px, -223px, 0) rotate(35deg);
            transform: translate3d(-243px, -223px, 0) rotate(35deg); }
  90% {
    -webkit-transform: translate3d(-223px, -243px, 0) rotate(45deg);
            transform: translate3d(-223px, -243px, 0) rotate(45deg); }
  100% {
    -webkit-transform: translate3d(-203px, -263px, 0) rotate(55deg);
            transform: translate3d(-203px, -263px, 0) rotate(55deg); } }

.Hidden_fill__EX0cw {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 20;
  position: relative;
  width: 250px;
  height: 250px;
  padding: 0;
  border-radius: 50% 50% 50% 50%;
  overflow: hidden;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }
  .Hidden_fill__EX0cw .Hidden_gooey-container__2ufqI {
    -webkit-filter: url(#gooey-fill);
            filter: url(#gooey-fill);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 300px;
    height: 300px;
    padding: 0;
    margin: 0;
    box-shadow: 0 0 0 18px #0000ff inset;
    border-radius: 50% 0 50% 50%;
    overflow: hidden; }
    .Hidden_fill__EX0cw .Hidden_gooey-container__2ufqI .Hidden_level__3Tznz,
    .Hidden_fill__EX0cw .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt {
      background: #ff4081; }
    .Hidden_fill__EX0cw .Hidden_gooey-container__2ufqI .Hidden_level__3Tznz {
      display: block;
      margin: 0 auto;
      position: absolute;
      top: 300px;
      left: 50%;
      width: 500px;
      height: 350px;
      text-align: center;
      -webkit-transform-origin: 50% 0;
              transform-origin: 50% 0;
      -webkit-transform: translate3d(-413px, -53px, 0) rotate(45deg);
              transform: translate3d(-413px, -53px, 0) rotate(45deg);
      -webkit-animation: Hidden_fill-level__2gQ54 5s linear infinite;
              animation: Hidden_fill-level__2gQ54 5s linear infinite;
      box-shadow: 0 0 100px 0 #0000ff inset, 0 0 200px 0 #0000ff inset; }

@-webkit-keyframes Hidden_fill-bubbles__2ZzTt {
  0% {
    -webkit-transform: translate3d(-100px, -20px, 0) rotate(0deg);
            transform: translate3d(-100px, -20px, 0) rotate(0deg); }
  50% {
    -webkit-transform: translate3d(100px, -40px, 0) rotate(180deg);
            transform: translate3d(100px, -40px, 0) rotate(180deg); }
  100% {
    -webkit-transform: translate3d(-100px, -20px, 0) rotate(360deg);
            transform: translate3d(-100px, -20px, 0) rotate(360deg); } }

@keyframes Hidden_fill-bubbles__2ZzTt {
  0% {
    -webkit-transform: translate3d(-100px, -20px, 0) rotate(0deg);
            transform: translate3d(-100px, -20px, 0) rotate(0deg); }
  50% {
    -webkit-transform: translate3d(100px, -40px, 0) rotate(180deg);
            transform: translate3d(100px, -40px, 0) rotate(180deg); }
  100% {
    -webkit-transform: translate3d(-100px, -20px, 0) rotate(360deg);
            transform: translate3d(-100px, -20px, 0) rotate(360deg); } }
    .Hidden_fill__EX0cw .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt {
      content: "";
      display: inline-block;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-transform: rotate(0deg) translate3d(0, 0, 0);
              transform: rotate(0deg) translate3d(0, 0, 0);
      box-shadow: 0 0 30px 20px #0000ff inset; }
    .Hidden_fill__EX0cw .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(1) {
      -webkit-animation: Hidden_fill-bubbles__2ZzTt 1.5s ease 0.5s infinite;
              animation: Hidden_fill-bubbles__2ZzTt 1.5s ease 0.5s infinite; }
    .Hidden_fill__EX0cw .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(2) {
      -webkit-animation: Hidden_fill-bubbles__2ZzTt 3s ease 0.25s infinite;
              animation: Hidden_fill-bubbles__2ZzTt 3s ease 0.25s infinite; }
    .Hidden_fill__EX0cw .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(3) {
      -webkit-animation: Hidden_fill-bubbles__2ZzTt 4.5s ease 0.16667s infinite;
              animation: Hidden_fill-bubbles__2ZzTt 4.5s ease 0.16667s infinite; }
    .Hidden_fill__EX0cw .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(4) {
      -webkit-animation: Hidden_fill-bubbles__2ZzTt 6s ease 0.125s infinite;
              animation: Hidden_fill-bubbles__2ZzTt 6s ease 0.125s infinite; }
    .Hidden_fill__EX0cw .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(5) {
      -webkit-animation: Hidden_fill-bubbles__2ZzTt 7.5s ease 0.1s infinite;
              animation: Hidden_fill-bubbles__2ZzTt 7.5s ease 0.1s infinite; }
    .Hidden_fill__EX0cw .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(6) {
      -webkit-animation: Hidden_fill-bubbles__2ZzTt 9s ease 0.08333s infinite;
              animation: Hidden_fill-bubbles__2ZzTt 9s ease 0.08333s infinite; }
    .Hidden_fill__EX0cw .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(7) {
      -webkit-animation: Hidden_fill-bubbles__2ZzTt 10.5s ease 0.07143s infinite;
              animation: Hidden_fill-bubbles__2ZzTt 10.5s ease 0.07143s infinite; }
    .Hidden_fill__EX0cw .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(8) {
      -webkit-animation: Hidden_fill-bubbles__2ZzTt 12s ease 0.0625s infinite;
              animation: Hidden_fill-bubbles__2ZzTt 12s ease 0.0625s infinite; }

@-webkit-keyframes Hidden_plasma__1pU-U {
  0% {
    -webkit-transform: scale(6) translate3d(20px, 0, 0);
            transform: scale(6) translate3d(20px, 0, 0); }
  50% {
    -webkit-transform: scale(1) translate3d(-60px, 0, 0);
            transform: scale(1) translate3d(-60px, 0, 0); }
  100% {
    -webkit-transform: scale(6) translate3d(20px, 0, 0);
            transform: scale(6) translate3d(20px, 0, 0); } }

@keyframes Hidden_plasma__1pU-U {
  0% {
    -webkit-transform: scale(6) translate3d(20px, 0, 0);
            transform: scale(6) translate3d(20px, 0, 0); }
  50% {
    -webkit-transform: scale(1) translate3d(-60px, 0, 0);
            transform: scale(1) translate3d(-60px, 0, 0); }
  100% {
    -webkit-transform: scale(6) translate3d(20px, 0, 0);
            transform: scale(6) translate3d(20px, 0, 0); } }

.Hidden_plasma__1pU-U {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 20;
  position: relative;
  width: 250px;
  height: 250px;
  padding: 0;
  border-radius: 50%;
  overflow: hidden; }
  .Hidden_plasma__1pU-U .Hidden_gooey-container__2ufqI {
    -webkit-filter: url(#gooey-plasma);
            filter: url(#gooey-plasma);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 300px;
    height: 300px;
    padding: 0;
    margin: 0;
    box-shadow: 0 0 0 20px #ff4081 inset;
    border-radius: 50%;
    overflow: hidden; }
    .Hidden_plasma__1pU-U .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      text-align: right; }
      .Hidden_plasma__1pU-U .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:before {
        content: "";
        display: inline-block;
        background: #ff4081;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        -webkit-transform: scale(6) translate3d(20px, 0, 0);
                transform: scale(6) translate3d(20px, 0, 0);
        box-shadow: 0 0 15px 0 #0000ff inset, 0 0 10px 0 #0000ff inset; }
    .Hidden_plasma__1pU-U .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(1) {
      -webkit-transform: translate(-50%, -50%) rotate(60deg);
              transform: translate(-50%, -50%) rotate(60deg); }
    .Hidden_plasma__1pU-U .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(1):before {
      -webkit-animation: Hidden_plasma__1pU-U 3.5s linear 0.25s infinite;
              animation: Hidden_plasma__1pU-U 3.5s linear 0.25s infinite; }
    .Hidden_plasma__1pU-U .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(2) {
      -webkit-transform: translate(-50%, -50%) rotate(120deg);
              transform: translate(-50%, -50%) rotate(120deg); }
    .Hidden_plasma__1pU-U .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(2):before {
      -webkit-animation: Hidden_plasma__1pU-U 4s linear 0.5s infinite;
              animation: Hidden_plasma__1pU-U 4s linear 0.5s infinite; }
    .Hidden_plasma__1pU-U .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(3) {
      -webkit-transform: translate(-50%, -50%) rotate(180deg);
              transform: translate(-50%, -50%) rotate(180deg); }
    .Hidden_plasma__1pU-U .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(3):before {
      -webkit-animation: Hidden_plasma__1pU-U 4.5s linear 0.75s infinite;
              animation: Hidden_plasma__1pU-U 4.5s linear 0.75s infinite; }
    .Hidden_plasma__1pU-U .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(4) {
      -webkit-transform: translate(-50%, -50%) rotate(240deg);
              transform: translate(-50%, -50%) rotate(240deg); }
    .Hidden_plasma__1pU-U .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(4):before {
      -webkit-animation: Hidden_plasma__1pU-U 5s linear 1s infinite;
              animation: Hidden_plasma__1pU-U 5s linear 1s infinite; }
    .Hidden_plasma__1pU-U .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(5) {
      -webkit-transform: translate(-50%, -50%) rotate(300deg);
              transform: translate(-50%, -50%) rotate(300deg); }
    .Hidden_plasma__1pU-U .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(5):before {
      -webkit-animation: Hidden_plasma__1pU-U 5.5s linear 1.25s infinite;
              animation: Hidden_plasma__1pU-U 5.5s linear 1.25s infinite; }
    .Hidden_plasma__1pU-U .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(6) {
      -webkit-transform: translate(-50%, -50%) rotate(360deg);
              transform: translate(-50%, -50%) rotate(360deg); }
    .Hidden_plasma__1pU-U .Hidden_gooey-container__2ufqI .Hidden_bubble__1ztrt:nth-child(6):before {
      -webkit-animation: Hidden_plasma__1pU-U 6s linear 1.5s infinite;
              animation: Hidden_plasma__1pU-U 6s linear 1.5s infinite; }

.Indel_indel-wrapper__2lOYE {
  padding-bottom: 40px; }
  .Indel_indel-wrapper__2lOYE svg {
    display: block; }
  .Indel_indel-wrapper__2lOYE .Indel_header__2cCGX {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .Indel_indel-wrapper__2lOYE .Indel_header__2cCGX .Indel_title-box__9l4Yi {
      display: flex;
      align-items: center; }
      .Indel_indel-wrapper__2lOYE .Indel_header__2cCGX .Indel_title-box__9l4Yi .Indel_title__EyXnt {
        font-size: 2.5em;
        font-weight: 500;
        margin-bottom: 8px;
        color: #3baad0; }
      .Indel_indel-wrapper__2lOYE .Indel_header__2cCGX .Indel_title-box__9l4Yi .Indel_icon__305vI {
        font-size: 10em;
        width: 30px;
        margin-right: 5px; }
        .Indel_indel-wrapper__2lOYE .Indel_header__2cCGX .Indel_title-box__9l4Yi .Indel_icon__305vI * {
          font-size: 1em; }
  .Indel_indel-wrapper__2lOYE .Indel_chart-wrapper__3Ouo2 {
    width: 100%;
    height: 500px; }
  .Indel_indel-wrapper__2lOYE .Indel_no-data-wrapper__3jzM7 {
    padding-left: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center; }
    .Indel_indel-wrapper__2lOYE .Indel_no-data-wrapper__3jzM7 .Indel_icon__305vI {
      margin-right: 5px;
      font-size: 1.5em;
      color: #ff9a00; }
    .Indel_indel-wrapper__2lOYE .Indel_no-data-wrapper__3jzM7 .Indel_text__1ypP9 {
      font-size: 1.2em;
      color: #ff9a00; }
    .Indel_indel-wrapper__2lOYE .Indel_no-data-wrapper__3jzM7 a {
      margin: 0 7px;
      text-decoration: underline;
      font-weight: bold; }
  .Indel_indel-wrapper__2lOYE .Indel_download-box__1YHt7 {
    display: flex;
    align-items: center; }
    .Indel_indel-wrapper__2lOYE .Indel_download-box__1YHt7 .Indel_desc__33EzN {
      opacity: 0.5;
      font-size: 0.9em; }
    .Indel_indel-wrapper__2lOYE .Indel_download-box__1YHt7 .Indel_icon__305vI {
      width: 25px;
      margin-left: 3px;
      cursor: pointer; }
      .Indel_indel-wrapper__2lOYE .Indel_download-box__1YHt7 .Indel_icon__305vI img {
        width: 100%; }

.reset-zoom {
  background-color: #74ccff;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  padding: 7px 10px;
  font-size: 0.9em;
  border-radius: 3px;
  border: 0px !important; }
  .reset-zoom:hover {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4); }

.Indel_seq-list-container__3fq-0 {
  overflow: hidden;
  margin-bottom: 10px; }
  .Indel_seq-list-container__3fq-0 .Indel_seq-list-header__2b_YQ {
    width: 100%;
    height: 40px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dbdbdb;
    color: #8a8a8a;
    cursor: pointer;
    justify-content: space-between; }
    .Indel_seq-list-container__3fq-0 .Indel_seq-list-header__2b_YQ .Indel_seq-title__5cJH9 {
      font-size: 1em; }
    .Indel_seq-list-container__3fq-0 .Indel_seq-list-header__2b_YQ .Indel_icon-box__1Zxdj {
      display: flex; }
  .Indel_seq-list-container__3fq-0 .Indel_seq-list-wrapper__3Hctx {
    padding: 10px;
    background-color: #f5f5f5;
    margin-bottom: 10px; }
    .Indel_seq-list-container__3fq-0 .Indel_seq-list-wrapper__3Hctx .Indel_title__EyXnt {
      opacity: 0.5;
      font-size: 0.9em;
      margin-bottom: 7px; }
    .Indel_seq-list-container__3fq-0 .Indel_seq-list-wrapper__3Hctx .Indel_label-wrapper__34k2a {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      .Indel_seq-list-container__3fq-0 .Indel_seq-list-wrapper__3Hctx .Indel_label-wrapper__34k2a .Indel_seq__r3Lxd {
        font-size: 0.8em;
        margin: 0 5px; }
        .Indel_seq-list-container__3fq-0 .Indel_seq-list-wrapper__3Hctx .Indel_label-wrapper__34k2a .Indel_seq__r3Lxd.Indel_target-seq__3wgnV {
          color: #ff6f6f; }
        .Indel_seq-list-container__3fq-0 .Indel_seq-list-wrapper__3Hctx .Indel_label-wrapper__34k2a .Indel_seq__r3Lxd.Indel_hover-seq__SU2cc {
          -webkit-transform: translate(0, -3px);
                  transform: translate(0, -3px);
          font-size: 1.4em; }

@media screen and (max-width: 1000px) {
  .Indel_chart-wrapper__3Ouo2 {
    height: 200px !important; } }

.Main_main-container__wlSV2 {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 64px;
  background: -webkit-linear-gradient(315deg, #13f1fc 0%, #0470dc 100%);
  background: linear-gradient(135deg, #13f1fc 0%, #0470dc 100%); }
  .Main_main-container__wlSV2:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: -1; }
  .Main_main-container__wlSV2 .Main_main-content__zX3pt {
    width: 85%;
    margin: auto;
    padding: 50px 0;
    max-width: 1300px; }
    .Main_main-container__wlSV2 .Main_main-content__zX3pt .Main_info-box__CIhFa {
      margin-bottom: 50px; }
      .Main_main-container__wlSV2 .Main_main-content__zX3pt .Main_info-box__CIhFa .Main_info-text__MbTLB {
        font-size: 3.5em;
        color: #ffffff;
        font-weight: bold;
        margin-bottom: 15px; }
      .Main_main-container__wlSV2 .Main_main-content__zX3pt .Main_info-box__CIhFa .Main_info__1rf7i {
        font-size: 1.3em;
        color: #ffffff; }
    .Main_main-container__wlSV2 .Main_main-content__zX3pt .Main_menu-container__2NRhB {
      display: flex;
      align-items: center; }
      .Main_main-container__wlSV2 .Main_main-content__zX3pt .Main_menu-container__2NRhB .Main_menu-box__2dmyS {
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 10px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        cursor: pointer; }
        .Main_main-container__wlSV2 .Main_main-content__zX3pt .Main_menu-container__2NRhB .Main_menu-box__2dmyS:hover {
          -webkit-transform: translate(0, -10px);
                  transform: translate(0, -10px); }
        .Main_main-container__wlSV2 .Main_main-content__zX3pt .Main_menu-container__2NRhB .Main_menu-box__2dmyS .Main_icon-box__1p-kw {
          width: 60%;
          height: 100%; }
          .Main_main-container__wlSV2 .Main_main-content__zX3pt .Main_menu-container__2NRhB .Main_menu-box__2dmyS .Main_icon-box__1p-kw img {
            width: 100%;
            height: 100%; }
        .Main_main-container__wlSV2 .Main_main-content__zX3pt .Main_menu-container__2NRhB .Main_menu-box__2dmyS .Main_icon-title__1rQjB {
          margin-top: 20px;
          color: #ffffff; }

@media screen and (max-width: 1000px) {
  .Main_info-text__MbTLB {
    font-size: 1.5em !important;
    text-align: center; }
  .Main_menu-container__2NRhB {
    flex-direction: column; }
    .Main_menu-container__2NRhB .Main_menu-box__2dmyS {
      margin-bottom: 15px !important; }
  .Main_main-content__zX3pt {
    width: 100% !important;
    overflow: auto;
    height: 100%; } }

.IndelReport_indel-wrapper__3RO0g .IndelReport_header__2h7Qa {
  margin-bottom: 20px; }
  .IndelReport_indel-wrapper__3RO0g .IndelReport_header__2h7Qa .IndelReport_title__31n1N {
    font-size: 2.5em;
    font-weight: 500;
    color: #3baad0; }
  .IndelReport_indel-wrapper__3RO0g .IndelReport_header__2h7Qa .IndelReport_desc__gOLw4 {
    color: #a0a0a0; }
  .IndelReport_indel-wrapper__3RO0g .IndelReport_header__2h7Qa b {
    color: #fd7474; }
    .IndelReport_indel-wrapper__3RO0g .IndelReport_header__2h7Qa b a {
      color: inherit;
      border-bottom: 1px solid; }
  .IndelReport_indel-wrapper__3RO0g .IndelReport_header__2h7Qa p {
    margin-bottom: 0;
    color: #ff9c9c; }

.IndelReport_indel-wrapper__3RO0g .IndelReport_button-box__1DDsD {
  margin-top: 10px; }

.DragBox_upload-icon__3ttYR img {
  width: 5%;
  margin-bottom: 10px;
  min-width: 30px;
  opacity: 0.5; }

.DragBox_upload-text__3pEUZ {
  opacity: 0.5; }

.Convert_convert-wrapper__2xddo .Convert_header__1IWAP {
  margin-bottom: 20px; }
  .Convert_convert-wrapper__2xddo .Convert_header__1IWAP .Convert_title__2Q4Jb {
    font-size: 2.5em;
    font-weight: 500;
    color: #3baad0; }
  .Convert_convert-wrapper__2xddo .Convert_header__1IWAP .Convert_desc__30v3M {
    color: #a0a0a0; }

.Convert_convert-wrapper__2xddo .Convert_button-box__1QdoP {
  margin-top: 60px;
  text-align: center; }

.Convert_select-wrapper__hDb38 {
  margin: 10px 0; }
  .Convert_select-wrapper__hDb38 .Convert_select-title__3Lz8h {
    padding: 10px 0;
    font-size: 1.2em;
    font-weight: 700; }
  .Convert_select-wrapper__hDb38 .Convert_select-content__37HB0 {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .Convert_select-wrapper__hDb38 .Convert_select-content__37HB0 .Convert_convert-box__1NZyn {
      width: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 10px; }
      .Convert_select-wrapper__hDb38 .Convert_select-content__37HB0 .Convert_convert-box__1NZyn .Convert_icon-box__1AX6c {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #e7f3fc;
        border: 2px solid #4b9af4;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 5px;
        -webkit-transition: all 0.2s;
        transition: all 0.2s; }
        .Convert_select-wrapper__hDb38 .Convert_select-content__37HB0 .Convert_convert-box__1NZyn .Convert_icon-box__1AX6c:hover {
          background-color: #4b9af4; }
        .Convert_select-wrapper__hDb38 .Convert_select-content__37HB0 .Convert_convert-box__1NZyn .Convert_icon-box__1AX6c .Convert_img__3NTY2 {
          width: 60%; }
          .Convert_select-wrapper__hDb38 .Convert_select-content__37HB0 .Convert_convert-box__1NZyn .Convert_icon-box__1AX6c .Convert_img__3NTY2 img {
            width: 100%; }
        .Convert_select-wrapper__hDb38 .Convert_select-content__37HB0 .Convert_convert-box__1NZyn .Convert_icon-box__1AX6c.Convert_select-convert__1ZIAq {
          background-color: #4b9af4; }
      .Convert_select-wrapper__hDb38 .Convert_select-content__37HB0 .Convert_convert-box__1NZyn .Convert_title-box__BRaC1 {
        font-size: 0.8em;
        opacity: 0.8; }

.Version_version-wrapper__1PsR9 .Version_header__2ifyO .Version_title__le7K6 {
  font-size: 2.5em;
  font-weight: 500;
  margin-bottom: 30px;
  color: #3baad0; }

.Version_version-wrapper__1PsR9 .Version_version-box__3rUIx {
  margin-bottom: 10px; }
  .Version_version-wrapper__1PsR9 .Version_version-box__3rUIx .Version_version-header__3HGYB {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    font-weight: 700;
    padding: 5px 0;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 5px; }
    .Version_version-wrapper__1PsR9 .Version_version-box__3rUIx .Version_version-header__3HGYB .Version_version__2SOO_ {
      margin-right: 10px; }
    .Version_version-wrapper__1PsR9 .Version_version-box__3rUIx .Version_version-header__3HGYB .Version_date__96y8O {
      font-size: 0.9em;
      opacity: 0.7; }
  .Version_version-wrapper__1PsR9 .Version_version-box__3rUIx .Version_history-wrapper__3YnxH {
    padding: 15px 0; }
    .Version_version-wrapper__1PsR9 .Version_version-box__3rUIx .Version_history-wrapper__3YnxH .Version_history-title__1SKek {
      font-size: 1.2em;
      margin-bottom: 10px;
      font-weight: bold;
      display: flex;
      align-items: center; }
      .Version_version-wrapper__1PsR9 .Version_version-box__3rUIx .Version_history-wrapper__3YnxH .Version_history-title__1SKek img {
        margin-left: 5px;
        width: 25px; }
    .Version_version-wrapper__1PsR9 .Version_version-box__3rUIx .Version_history-wrapper__3YnxH .Version_history__3GkYG {
      margin-bottom: 5px;
      display: flex;
      align-items: center; }
      .Version_version-wrapper__1PsR9 .Version_version-box__3rUIx .Version_history-wrapper__3YnxH .Version_history__3GkYG::before {
        content: "";
        display: block;
        margin-right: 5px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #666666; }

html,
body,
canvas {
  margin: 0px;
  height: 100vh;
  font-size: 0; }

#Intro_punch__BppCU {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  background-color: transparent;
  border: 0;
  outline: 0;
  font-size: 30px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
  border: 1px solid transparent;
  padding: 5px 20px;
  -webkit-transition: all 350ms;
  transition: all 350ms; }

#Intro_punch__BppCU:hover {
  border: 1px solid white; }

.Intro_intro-wrapper__Phdck {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: -webkit-linear-gradient(315deg, #002722 0%, #0157ad 100%);
  background: linear-gradient(135deg, #002722 0%, #0157ad 100%); }
  .Intro_intro-wrapper__Phdck.Intro_stop__3It7i {
    -webkit-animation: Intro_close__AXhBS 1.5s;
            animation: Intro_close__AXhBS 1.5s; }
  .Intro_intro-wrapper__Phdck.Intro_hidden__1dKBW {
    display: none; }
  .Intro_intro-wrapper__Phdck .Intro_img-box__3LYTH {
    width: 500px;
    height: 500px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .Intro_intro-wrapper__Phdck .Intro_img-box__3LYTH .Intro_img__1stlW {
      width: 80%;
      opacity: 0.5;
      -webkit-animation: Intro_opAnime__3uLoU 41s ease infinite;
              animation: Intro_opAnime__3uLoU 41s ease infinite; }
      .Intro_intro-wrapper__Phdck .Intro_img-box__3LYTH .Intro_img__1stlW img {
        width: 100%; }
  .Intro_intro-wrapper__Phdck .Intro_logo-box__v3l_K {
    display: block;
    color: #ffffff;
    font-size: 1.5em;
    width: 400px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto; }
    .Intro_intro-wrapper__Phdck .Intro_logo-box__v3l_K div {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap; }
  .Intro_intro-wrapper__Phdck .Intro_logo__8ZcXX {
    -webkit-animation: Intro_showup__1t7LW 4s infinite;
            animation: Intro_showup__1t7LW 4s infinite;
    width: 45px; }
  .Intro_intro-wrapper__Phdck .Intro_desc__1kJ9N {
    width: 0px;
    -webkit-animation: Intro_reveal__1p-a0 4s infinite;
            animation: Intro_reveal__1p-a0 4s infinite; }
    .Intro_intro-wrapper__Phdck .Intro_desc__1kJ9N span {
      margin-left: -355px;
      -webkit-animation: Intro_slidein__2CWPu 4s infinite;
              animation: Intro_slidein__2CWPu 4s infinite; }

.Intro_loading-logo__1bY2e {
  position: absolute;
  height: 500px;
  width: 500px;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  background: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.25)), to(rgba(10, 3, 70, 0.25)));
  background: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.25), rgba(10, 3, 70, 0.25));
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(10, 3, 70, 0.25));
  background-size: 400% 400%;
  border-radius: 360px;
  padding: 50px;
  -webkit-animation: Intro_loadLogoBack__lpZW3 41s ease infinite;
          animation: Intro_loadLogoBack__lpZW3 41s ease infinite; }
  .Intro_loading-logo__1bY2e svg {
    height: 100%;
    width: 100%;
    fill: white;
    stroke: white; }
    .Intro_loading-logo__1bY2e svg path {
      fill: white;
      stroke: white; }
    .Intro_loading-logo__1bY2e svg * {
      fill: white !important;
      stroke: white; }
  .Intro_loading-logo__1bY2e:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 360px;
    -webkit-animation: Intro_pulse__3OyCd 2s ease-out infinite;
            animation: Intro_pulse__3OyCd 2s ease-out infinite;
    border: 0px solid transparent; }
  .Intro_loading-logo__1bY2e:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 360px;
    -webkit-animation: Intro_pulseBig__2oLw7 2s ease-out infinite;
            animation: Intro_pulseBig__2oLw7 2s ease-out infinite;
    border: 0px solid transparent; }

@-webkit-keyframes Intro_pulse__3OyCd {
  0% {
    border: 0px solid rgba(255, 255, 255, 0.5);
    top: 0px;
    left: 0px; }
  90% {
    border: 25px solid rgba(255, 255, 255, 0); } }

@keyframes Intro_pulse__3OyCd {
  0% {
    border: 0px solid rgba(255, 255, 255, 0.5);
    top: 0px;
    left: 0px; }
  90% {
    border: 25px solid rgba(255, 255, 255, 0); } }

@-webkit-keyframes Intro_pulseBig__2oLw7 {
  0% {
    border: 0px solid rgba(255, 255, 255, 0.5);
    top: 0px;
    left: 0px; }
  90% {
    border: 50px solid rgba(255, 255, 255, 0); } }

@keyframes Intro_pulseBig__2oLw7 {
  0% {
    border: 0px solid rgba(255, 255, 255, 0.5);
    top: 0px;
    left: 0px; }
  90% {
    border: 50px solid rgba(255, 255, 255, 0); } }

@-webkit-keyframes Intro_animate__3HvKl {
  0% {
    background-position: -500%; }
  100% {
    background-position: 500%; } }

@keyframes Intro_animate__3HvKl {
  0% {
    background-position: -500%; }
  100% {
    background-position: 500%; } }

@-webkit-keyframes Intro_opAnime__3uLoU {
  0% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes Intro_opAnime__3uLoU {
  0% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@-webkit-keyframes Intro_showup__1t7LW {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes Intro_showup__1t7LW {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes Intro_slidein__2CWPu {
  0% {
    margin-left: -800px; }
  20% {
    margin-left: -800px; }
  35% {
    margin-left: 0px; }
  100% {
    margin-left: 0px; } }

@keyframes Intro_slidein__2CWPu {
  0% {
    margin-left: -800px; }
  20% {
    margin-left: -800px; }
  35% {
    margin-left: 0px; }
  100% {
    margin-left: 0px; } }

@-webkit-keyframes Intro_reveal__1p-a0 {
  0% {
    opacity: 0;
    width: 0px; }
  20% {
    opacity: 1;
    width: 0px; }
  30% {
    width: 220px; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0;
    width: 220px; } }

@keyframes Intro_reveal__1p-a0 {
  0% {
    opacity: 0;
    width: 0px; }
  20% {
    opacity: 1;
    width: 0px; }
  30% {
    width: 220px; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0;
    width: 220px; } }

@-webkit-keyframes Intro_close__AXhBS {
  0% {
    opacity: 1;
    display: flex; }
  90% {
    opacity: 0; }
  100% {
    display: none;
    visibility: hidden; } }

@keyframes Intro_close__AXhBS {
  0% {
    opacity: 1;
    display: flex; }
  90% {
    opacity: 0; }
  100% {
    display: none;
    visibility: hidden; } }

.InnerLoader_loader-wrapper__1akad {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .InnerLoader_loader-wrapper__1akad::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: -1; }

.InnerLoader_lds-ellipsis__32nP2 {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }

.InnerLoader_lds-ellipsis__32nP2 div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.InnerLoader_lds-ellipsis__32nP2 div:nth-child(1) {
  left: 8px;
  -webkit-animation: InnerLoader_lds-ellipsis1__15p2A 0.6s infinite;
          animation: InnerLoader_lds-ellipsis1__15p2A 0.6s infinite; }

.InnerLoader_lds-ellipsis__32nP2 div:nth-child(2) {
  left: 8px;
  -webkit-animation: InnerLoader_lds-ellipsis2__1GYSH 0.6s infinite;
          animation: InnerLoader_lds-ellipsis2__1GYSH 0.6s infinite; }

.InnerLoader_lds-ellipsis__32nP2 div:nth-child(3) {
  left: 32px;
  -webkit-animation: InnerLoader_lds-ellipsis2__1GYSH 0.6s infinite;
          animation: InnerLoader_lds-ellipsis2__1GYSH 0.6s infinite; }

.InnerLoader_lds-ellipsis__32nP2 div:nth-child(4) {
  left: 56px;
  -webkit-animation: InnerLoader_lds-ellipsis3__1FwD0 0.6s infinite;
          animation: InnerLoader_lds-ellipsis3__1FwD0 0.6s infinite; }

@-webkit-keyframes InnerLoader_lds-ellipsis1__15p2A {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes InnerLoader_lds-ellipsis1__15p2A {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes InnerLoader_lds-ellipsis3__1FwD0 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@keyframes InnerLoader_lds-ellipsis3__1FwD0 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@-webkit-keyframes InnerLoader_lds-ellipsis2__1GYSH {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0); } }

@keyframes InnerLoader_lds-ellipsis2__1GYSH {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0); } }

* {
  box-sizing: border-box; }

body {
  font-size: 14px;
  font-family: "Roboto", sans-serif; }

.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #ff9a9f !important; }

