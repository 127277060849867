$fontSize: 2.5em;
$fontColor: #3baad0;
$fontWeight: 500;
$marginBottom: 8px;
$descColor: #a0a0a0;

$targetVal: #e54b4b;
$insertionVal: #bc9eec;
$deletionVal: #91c6ff;
$originVal: #ffc352;
$subVal: #61bfad;
