@import "styles/common.scss";

.indel-wrapper {
  padding-bottom: 40px;
  svg {
    display: block;
  }

  .header {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-box {
      display: flex;
      align-items: center;
      .title {
        font-size: $fontSize;
        font-weight: $fontWeight;
        margin-bottom: $marginBottom;
        color: $fontColor;
      }
      .icon {
        font-size: 10em;
        width: 30px;
        margin-right: 5px;

        & * {
          font-size: 1em;
        }
      }
    }
  }

  .chart-wrapper {
    width: 100%;
    height: 500px;
  }

  .no-data-wrapper {
    padding-left: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .icon {
      margin-right: 5px;
      font-size: 1.5em;
      color: #ff9a00;
    }
    .text {
      font-size: 1.2em;
      color: #ff9a00;
    }

    a {
      margin: 0 7px;
      text-decoration: underline;
      font-weight: bold;
    }
  }

  .download-box {
    display: flex;
    align-items: center;

    .desc {
      opacity: 0.5;
      font-size: 0.9em;
    }

    .icon {
      width: 25px;
      margin-left: 3px;
      cursor: pointer;

      img {
        width: 100%;
      }
    }
  }
}

:global {
  .reset-zoom {
    background-color: #74ccff;
    color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    padding: 7px 10px;
    font-size: 0.9em;
    border-radius: 3px;
    border: 0px !important;

    &:hover {
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    }
  }
}

.seq-list-container {
  overflow: hidden;
  margin-bottom: 10px;
  .seq-list-header {
    width: 100%;
    height: 40px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(219, 219, 219);
    color: rgb(138, 138, 138);
    cursor: pointer;
    justify-content: space-between;

    .seq-title {
      font-size: 1em;
    }

    .icon-box {
      display: flex;
    }
  }
  .seq-list-wrapper {
    padding: 10px;
    background-color: #f5f5f5;
    margin-bottom: 10px;
    .title {
      opacity: 0.5;
      font-size: 0.9em;
      margin-bottom: 7px;
    }

    .label-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .seq {
        font-size: 0.8em;
        margin: 0 5px;

        &.target-seq {
          color: #ff6f6f;
        }

        &.hover-seq {
          transform: translate(0, -3px);
          font-size: 1.4em;
        }
      }
    }
  }
}

@media screen and (max-width: "1000px") {
  .chart-wrapper {
    height: 200px !important;
  }
}
