.logo {
  display: flex;
  cursor: pointer;
  align-items: center;

  .logo-text {
    font-size: 1.4em;
    color: #ffffff;
    font-weight: bold;
    margin-right: 10px;
  }

  .img-box {
    width: 40px;
    margin-right: 5px;
    img {
      width: 100%;
    }
  }
}

.header {
  // background: rgb(44, 172, 209);
  // background: linear-gradient(
  //   138deg,
  //   rgba(44, 172, 209, 1) 0%,
  //   rgba(44, 101, 177, 1) 100%
  // ) !important;
}

.header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  max-width: 1300px;
  margin: auto;

  .right-side,
  .left-side {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-box {
    margin: 0 10px;
    cursor: pointer;
    opacity: 0.8;
    transition: all 0.3s;

    &:hover {
      opacity: 1;
    }
    .menu {
      color: #ffffff;
      font-size: 0.9em;
    }
  }

  .run-sample {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
    position: relative;

    svg {
      height: 35px;
      width: 110px;
      fill: none;
      stroke-width: 3;
      display: block;

      .o1 {
        stroke: rgba(#ffffff, 0.5);
        fill: transparent;
        transition: all 1s ease-in-out;
      }

      .o2 {
        stroke: white;
        stroke-dasharray: 20 420;
        stroke-dashoffset: 20;
        transition: all 1s ease-in-out;
      }
    }

    span {
      position: absolute;
      margin: auto 0;
      text-transform: uppercase;
      font-size: 0.9em;
      color: #ffffff;
    }

    &:hover {
      .o1 {
      }

      .o2 {
        stroke-dashoffset: -420;
      }
    }
  }

  .logout {
    color: #ffffff;
    cursor: pointer;
    font-size: 1.1em;
    font-weight: bold;
  }
}

@media screen and (max-width: 700px) {
  .header-box {
    .menu-box,
    .right-side {
      display: none;
    }
  }
}
