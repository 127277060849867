.upload-icon {
  img {
    width: 5%;
    margin-bottom: 10px;
    min-width: 30px;
    opacity: 0.5;
  }
}

.upload-text {
  opacity: 0.5;
}
