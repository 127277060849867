@import url("https://fonts.googleapis.com/css?family=Roboto:100,400,500&display=swap");

* {
  box-sizing: border-box;
}

body {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #ff9a9f !important;
}
