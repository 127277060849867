@import "styles/common.scss";

.version-wrapper {
  .header {
    .title {
      font-size: $fontSize;
      font-weight: $fontWeight;
      margin-bottom: 30px;
      color: $fontColor;
    }
  }

  .version-box {
    margin-bottom: 10px;

    .version-header {
      display: flex;
      align-items: center;
      font-size: 1.5em;
      font-weight: 700;
      padding: 5px 0;
      border-bottom: 1px solid rgb(221, 221, 221);
      margin-bottom: 5px;

      .version {
        margin-right: 10px;
      }

      .date {
        font-size: 0.9em;
        opacity: 0.7;
      }
    }

    .history-wrapper {
      padding: 15px 0;

      .history-title {
        font-size: 1.2em;
        margin-bottom: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;

        img {
          margin-left: 5px;
          width: 25px;
        }
      }

      .history {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        &::before {
          content: "";
          display: block;
          margin-right: 5px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: rgb(102, 102, 102);
        }
      }
    }
  }
}
